<template>
	<div>
		<div class="row">
			<div class="col-12">
				<div class="card">

					<!-- card header -->
					<div class="card-header">
						<h4>Preencha os dados abaixo</h4>

						<a @click="newData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
					</div>

					<!-- card body -->
					<div class="card-body">
						<div class="row">

							<!-- product_id -->
							<div class="col-md-8">
								<div class="form-group">
									<label class="form-control-label" for="input-product_id">Selecione o Produto</label>
									<v-select :options="productsOptions" label="title" v-model="product_id" :reduce="title => title.id" @input="validateProduct()"></v-select>
									<div class="invalid-feedback" v-if="errors.product_id">
										<p>{{ errors.product_id[0] }}</p>
									</div>
								</div>
							</div>

							<!-- amount -->
							<div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-amount">Quantidade</label>
									<input type="number" id="input-amount" v-bind:class="{'is-invalid': errors.amount}"   class="form-control" placeholder="Quantidade" v-model="amount" required="true" @change="calculateSummary()">
									<div class="invalid-feedback" v-if="errors.amount">
										<p>{{ errors.amount[0] }}</p>
									</div>
								</div>
							</div>

							<!-- source -->
							<div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-type">Tipo</label>
									<v-select :options="sourceOptions" label="label" v-model="source" :reduce="label => label.id"></v-select>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-8">

				<div class="card">
					<div class="card-body">
						<div class="row">

							<!-- client_id -->
							<div class="col-md-12" v-if="source == 'client'">
								<div class="form-group">
									<label class="form-control-label" for="input-client_id">Selecione o Cliente</label>
									<v-select :options="clientsOptions" label="name" v-model="client_id" :reduce="name => name.id" @input="validateClient()"></v-select>
									<div class="invalid-feedback" v-if="errors.client_id">
										<p>{{ errors.client_id[0] }}</p>
									</div>
								</div>
							</div>

							<!-- seller_id -->
							<div class="col-md-12" v-if="source == 'seller'">
								<div class="form-group">
									<label class="form-control-label" for="input-client_id">Selecione o Vendedor</label>
									<v-select :options="sellersOptions" label="name" v-model="seller_id" :reduce="name => name.id"></v-select>
									<div class="invalid-feedback" v-if="errors.seller_id">
										<p>{{ errors.seller_id[0] }}</p>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Entrega / Retirada</h6>
						<div class="row">

							<!-- delivery_type -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-delivery_type">Tipo de Entrega</label>
									<v-select :options="shippingOptions" label="label" v-model="delivery_type" :reduce="label => label.id"></v-select>
									<div class="invalid-feedback" v-if="errors.delivery_type">
										<p>{{ errors.delivery_type[0] }}</p>
									</div>
								</div>
							</div>

							<div class="col-md-6">
								<div class="form-group input-group-merge">
									<label class="form-control-label" for="input-cep">Digite o CEP para consultar o valor do frete</label>
									<div class="input-group">
										<input type="text" v-bind:class="{'is-invalid': errors.cep}"  maxlength="20" class="form-control" placeholder="CEP" v-model="cep" required="true" :disabled="delivery_type !== 'to_remove' ? false : true">
										<div class="input-group-append">
											<button class="btn btn-outline-secondary" @click="searchCEP()" type="button" id="button-addon2"><i class="fas fa-search"></i></button>
										</div>
									</div>
								</div>
							</div>

						</div>

						<!-- list delivery options -->
						<div class="row" v-if="delivery_calculated">
							<div class="col-md-12">

								<div class="row">

									<!-- list -->
									<div class="col-md-4" style="margin-bottom: 15px;" v-for="item,index in delivery_options" :key="index" @click="selectDelivery(item)">
										<div :class="delivery_selected.name == item.name ? 'item-delivery active': 'item-delivery'">
											<img :src="item.company.picture"/>
											<span class="company">{{ item.name }}</span>
											<span class="time">{{ item.delivery_time }} dias</span>
											<span class="value">R$ {{ item.price }}</span>
										</div>
									</div>

								</div>

							</div>
						</div>

					</div>
				</div>

				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Pagamento / Desconto</h6>
						<div class="row">

							<!-- payment_type -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-payment_type">Tipo de Pagamento</label>
									<v-select :options="paymentOptions" label="label" v-model="payment_type" :reduce="label => label.id"></v-select>
									<div class="invalid-feedback" v-if="errors.payment_type">
										<p>{{ errors.payment_type[0] }}</p>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

			</div>

			<div class="col-md-4">
				
				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Produto</h6>
						<div class="row">
							<img v-if="selectedProduct.attachments.length > 0" :src="'/storage/products/'+selectedProduct.attachments[0].file" style="width: 100%;"/>
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Resumo</h6>

						<div class="row">
							<div class="col-md-6">
								<p>Valor Unitário Produto:</p>
							</div>
							<div class="col-md-6">
								<p><b>R$ {{ _formatPrice(this.summary.unitary_value) }}</b></p>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<p>Quantidade:</p>
							</div>
							<div class="col-md-6">
								<p><b>{{parseInt(this.summary.amount)}}</b></p>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<p>Subtotal:</p>
							</div>
							<div class="col-md-6">
								<p><b>R$ {{ _formatPrice(this.summary.subtotal) }}</b></p>
							</div>
						</div>

						<div class="row mt-4">
							<div class="col-md-6">
								<p>Frete / Entrega:</p>
							</div>
							<div class="col-md-6">
								<p><b>R$ {{ _formatPrice(this.summary.shipping) }}</b></p>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<p>Desconto:</p>
							</div>
							<div class="col-md-6">
								<p><b>R$ {{ _formatPrice(this.summary.discount) }}</b></p>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<p>Total:</p>
							</div>
							<div class="col-md-6">
								<p><b>R$ {{ _formatPrice(this.summary.total) }}</b></p>
							</div>
						</div>

					</div>
				</div>

			</div>

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div><!-- row -->
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import utilsService from '../../utils.js';
	export default {
		mixins: [ApiService, utilsService],
		data() {
			return {
				amount: 1,
				cep: '',
				subtotal: "",
				delivery_type: "to_remove",
				delivery_discount: "",
				delivery_calculated: false,
				delivery_options: [],
				delivery_selected: {},
				deliveryConfigurated: 'Melho Envio',
				coupon: "",
				coupon_discount: "",
				discount: 0,
				total: "",
				payment_type: "",
				seller_id: "",
				sellersOptions: [],
				client_id: "",
				clientsOptions: [],
				paymentOptions: [{
					id: 'pix',
					label: "Pix - Manual"
				},{
					id: 'card',
					label: "Cartão - Manual"
				}],
				product_id: "",
				productsOptions: [],
				selectedProduct: {
					attachments: []
				},
				shippingOptions: [{
					id: 'delivery',
					label: "Entregar"
				},{
					id: 'to_remove',
					label: "Retirar"
				}],
				source: "client",
				sourceOptions: [{
					id: 'client',
					label: "Cliente"
				},{
					id: 'seller',
					label: "Vendedor"
				}],
				summary: {
					unitary_value: 0,
					amount: 0,
					subtotal: 0,
					shipping: 0,
					coupon: 0,
					discount: 0,
					total: 0
				},
				errors: [],
				message: "",
				authMelhorEnvio: false,
				loading: false,
				fullPage: true
			}
		},
		mounted() {
			this.searchConfigurations();
			this.loadSellers();
			this.loadClients();
			this.loadProducts();
			this.verifyTokenMelhorEnvio();
		},
		methods: {
			newData() {
				let _this = this;

				//validate delivery_type
				if(_this.delivery_type !== 'to_remove' && _this.summary.shipping == 0){

					this.$swal({
						title: 'Atenção',
						text: 'Valor do Frete inválido. Não pode ser R$ 0,00',
						icon: 'warning'
					});

				} else {

					_this.errors = [];
					_this.message = "";
					_this.loading = true;
					let dataInfo = {
						"amount" : _this.amount,
						"subtotal" : _this.summary.subtotal,
						"delivery_type" : _this.delivery_type,
						"delivery_discount" : _this.delivery_discount,
						"coupon" : _this.coupon,
						"coupon_discount" : _this.coupon_discount,
						"discount" : _this.discount,
						"total" : _this.summary.total,
						"payment_type" : _this.payment_type,
						"seller_id" : _this.seller_id,
						"client_id" : _this.client_id,
						"product_id" : _this.product_id,
						"delivery_infos": _this.delivery_selected,
						"last": ""
					};
					axios.post("/admin/sales", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,true);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			resetForm() {
				this.amount = '';
				this.subtotal = '';
				this.delivery_type = '';
				this.delivery_discount = '';
				this.coupon = '';
				this.coupon_discount = '';
				this.discount = '';
				this.total = '';
				this.payment_type = '';
				this.seller_id = '';
				this.client_id = '';
				this.product_id = '';

				this.errors = [];

			},
			loadSellers() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/sellers").then((res) => {
					_this.sellersOptions = _this.sellersOptions.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadClients() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/clients").then((res) => {
					_this.clientsOptions = _this.clientsOptions.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadProducts() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/products").then((res) => {
					_this.productsOptions = _this.productsOptions.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			calculateSummary(){

				//validate discount
				if(this.discount > this.summary.total){

					this.$swal({
						title: 'Atenção',
						text: 'Valor do Desconto não pode ser maior que o Valor Total da Venda',
						icon: 'warning'
					});
					this.discount = "";

				} else {

					//validate unitary value
					this.selectedProduct.prices.map((rowPrice) => {
						if(this.amount >= rowPrice.amount_start && this.amount <= rowPrice.amount_end){
							this.summary.unitary_value = rowPrice.value.toFixed(2);
						}
					});

					this.summary.discount = this.discount;
					this.summary.amount = this.amount;
					this.summary.subtotal = this.summary.amount * this.summary.unitary_value;

					this.summary.total = (this.summary.subtotal + this.summary.shipping) - this.summary.coupon - this.summary.discount;

				}

			},
			searchCEP(){
				let _this = this;

				if(_this.product_id !== "" && _this.amount > 0 && _this.cep !== ""){

					//validate delivery configurated
					if(_this.deliveryConfigurated == "Melhor Envio"){

						_this.loading = true;

						var dataInfo = {
							product: _this.product_id,
							cep: _this.cep,
							amount: _this.amount
						};

						axios.post("/admin/melhorenvio/search", dataInfo).then((res) => {

							_this.delivery_options = [];
							
							//read delivery options
							res.data.data.map((rowDelivery) => {
								if(!rowDelivery.error){
									_this.delivery_options.push(rowDelivery);
								}
							});

							_this.delivery_calculated = true;

							_this.loading = false;
						}).catch((err) => {
							_this.loading = false;
						});
					
					} else {

						_this.loading = true;

						var dataInfo = {
							product: _this.product_id,
							cep: _this.cep,
							amount: _this.amount
						};

						axios.post("/admin/correios/search", dataInfo).then((res) => {

							_this.delivery_options = [];
							
							// //read delivery options
							// res.data.data.map((rowDelivery) => {
							// 	if(!rowDelivery.error){
							// 		_this.delivery_options.push(rowDelivery);
							// 	}
							// });

							// _this.delivery_calculated = true;

							_this.loading = false;
						}).catch((err) => {
							_this.loading = false;
						});

					}

				}

			},
			selectDelivery(item){
				this.delivery_selected = item;
				this.summary.shipping = parseFloat(item.price);
				this.calculateSummary();
			},
			verifyTokenMelhorEnvio(){
				let _this = this;
				_this.loading = true;
				axios.get("/admin/melhorenvio/token").then((res) => {
					this.authMelhorEnvio = res.data.status;

					//validate shippingOptions
					if(res.data.status){

						_this.shippingOptions = [{
							id: 'delivery',
							label: "Entregar"
						},{
							id: 'to_remove',
							label: "Retirar"
						}];

					} else {

						_this.shippingOptions = [{
							id: 'to_remove',
							label: "Retirar"
						}];

					}

					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			validateClient(){
				this.clientsOptions.map((rowClient) => {
					if(rowClient.id == this.client_id){
						this.cep = rowClient.zip_code;
					}
				});
			},
			validateProduct(){
				//search product info
				this.productsOptions.map((rowProduct) => {
					if(rowProduct.id == this.product_id){
						this.selectedProduct = rowProduct;
						this.calculateSummary();
					}
				});
			},
			searchConfigurations() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/configurations/search/system").then((res) => {

          //read all to validate delivery plataform configurated
          res.data.map((rowConfiguration) => {
            if(rowConfiguration.name == "Plataforma Entrega"){
              if(rowConfiguration.content == "Melhor Envio"){
                _this.deliveryConfigurated = "Melhor Envio";
              } else {
                _this.deliveryConfigurated = "Correios";
              }
            }
          });

					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			}
		}
	}
</script>
