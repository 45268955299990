<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos níveis cadastrados</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalComissions"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="comissionsItems.length"
						:columns="tableColumns"
						:rows="comissionsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum nível cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalComissions" tabindex="-1" aria-labelledby="modalComissionsLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalComissionsLabel">{{comissionsTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

							<!-- level -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-level">Nível</label>
									<input type="number" id="input-level" v-bind:class="{'is-invalid': errors.level}"   class="form-control" placeholder="Nível" v-model="comission.level" required="true" >
									<div class="invalid-feedback" v-if="errors.level">
										<p>{{ errors.level[0] }}</p>
									</div>
								</div>
							</div>
							

							<!-- direct_sale -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-direct_sale">Venda Direta</label>
									<div class="input-group mb-3">
										<input type="number" id="input-direct_sale" v-bind:class="{'is-invalid': errors.direct_sale}"   class="form-control" placeholder="Venda Direta" v-model="comission.direct_sale" required="true" >
										<div class="input-group-prepend">
											<span class="input-group-text">%</span>
										</div>
									</div>
									<div class="invalid-feedback" v-if="errors.direct_sale">
										<p>{{ errors.direct_sale[0] }}</p>
									</div>
								</div>
							</div>
							

							<!-- indication_sale -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-indication_sale">Venda Indicação</label>
									<div class="input-group mb-3">
										<input type="number" id="input-indication_sale" v-bind:class="{'is-invalid': errors.indication_sale}"   class="form-control" placeholder="Venda Indicação" v-model="comission.indication_sale" required="true" >
										<div class="input-group-prepend">
											<span class="input-group-text" id="basic-addon1">%</span>
										</div>
									</div>
									<div class="invalid-feedback" v-if="errors.indication_sale">
										<p>{{ errors.indication_sale[0] }}</p>
									</div>
								</div>
							</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				comissionsTitle: "Novo Nível",
				comission: {
					level: '',
					direct_sale: '',
					indication_sale: '',
				},
				isEditComissions: false,
				comissionsID: "",
				comissionsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'Nível',
						field: 'level',
					},
					{
						label: 'Venda Direta %',
						field: 'direct_sale',
					},
					{
						label: 'Venda Indicação %',
						field: 'indication_sale',
					},
					{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},
					{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/comissions").then((res) => {
					_this.comissionsItems = _this.comissionsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditComissions == true){

					let dataInfo = {
						"level" : _this.comission.level,
						"direct_sale" : _this.comission.direct_sale,
						"indication_sale" : _this.comission.indication_sale,
						"_method": "PATCH"
					};
					axios.post("/admin/comissions/"+_this.comissionsID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalComissions').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"level" : _this.comission.level,
						"direct_sale" : _this.comission.direct_sale,
						"indication_sale" : _this.comission.indication_sale,
						"last": ""
					};
					axios.post("/admin/comissions", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.comissionsItems.push(res.data.data);
						$('#modalComissions').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Nível",
					text: "Tem certeza que deseja excluir esse Nível?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/comissions/"+ID).then((res) => {

							_this.comissions.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.comission = item;
				this.isEditComissions = true;
				this.comissionsID = item.id;
				this.comissionsTitle = "Editar Nível";

				$("#modalComissions").modal('show');

			},
			resetForm(){

				this.comission = {
					level: '',
					direct_sale: '',
					indication_sale: '',
				};

				this.errors = [];

				this.isEditComissions = false;
				this.comissionsID = "";
				this.comissionsTitle = "Novo Nível";

			}
		}
	}
</script>
