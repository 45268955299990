<template>
	<div>
		<div class="row">
			<div class="col-12">

				<div class="card">

					<!-- card header -->
					<div class="card-header">
						<h4>Preencha os dados abaixo</h4>

						<a @click="updateData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
					</div>
					<!-- end card header -->

					<!-- card body -->
					<div class="card-body">

						<!-- form -->
						<div class="row">
							<div class="col-md-6">
								<h6 class="heading-small text-muted mb-4">Dados</h6>
							</div>
							<div class="col-md-6">
								<div class="text-right">
									<p :class="total_balance < 0 ? 'text-danger' : 'text-success'"><b class="text-primary">Saldo Total:&nbsp;&nbsp;&nbsp;</b> R$ {{ total_balance.toFixed(2) }}</p>
								</div>
							</div>
						</div>
						
						<div class="">
							<div class="row">

								<!-- name -->
								<div class="col-md-8">
									<div class="form-group">
										<label class="form-control-label" for="input-name">Nome</label>
										<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}"   class="form-control" placeholder="Nome" v-model="name" required="true" >
										<div class="invalid-feedback" v-if="errors.name">
											<p>{{ errors.name[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end name -->

								<!-- cpf -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-cpf">CPF</label>
										<input type="text" id="input-cpf" v-bind:class="{'is-invalid': errors.cpf}"  maxlength="15" class="form-control" placeholder="CPF" v-model="cpf" required="true" v-mask="'###.###.###-##'">
										<div class="invalid-feedback" v-if="errors.cpf">
											<p>{{ errors.cpf[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end cpf -->	

								<!-- phone -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-phone">Telefone</label>
										<input type="text" id="input-phone" v-bind:class="{'is-invalid': errors.phone}"  maxlength="20" class="form-control" placeholder="Telefone" v-model="phone" required="false" v-mask="'(##) #####-####'">
										<div class="invalid-feedback" v-if="errors.phone">
											<p>{{ errors.phone[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end phone -->

								<!-- whatsapp -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-whatsapp">Whatsapp</label>
										<input type="text" id="input-whatsapp" v-bind:class="{'is-invalid': errors.whatsapp}"  maxlength="20" class="form-control" placeholder="Whatsapp" v-model="whatsapp" required="true" v-mask="'(##) #####-####'">
										<div class="invalid-feedback" v-if="errors.whatsapp">
											<p>{{ errors.whatsapp[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end whatsapp -->

								<!-- birth_date -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-birth_date">Data de Nascimento</label>
										<input type="date" id="input-birth_date" v-bind:class="{'is-invalid': errors.birth_date}"   class="form-control" placeholder="Data de Nascimento" v-model="birth_date" required="false" >
										<div class="invalid-feedback" v-if="errors.birth_date">
											<p>{{ errors.birth_date[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end birth_date -->

							</div>
						</div>
						<!-- end form -->

					</div><!-- card body -->
				</div>

				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Endereço</h6>
						<div class="row">

							<div class="col-md-3">
								<div class="form-group input-group-merge">
									<label class="form-control-label" for="input-cep">CEP</label>
									<div class="input-group">
										<input type="text" v-bind:class="{'is-invalid': errors.cep}"  maxlength="20" class="form-control" placeholder="CEP" v-model="cep" required="true">
										<div class="input-group-append">
											<button class="btn btn-outline-secondary" @click="searchCEP()" type="button" id="button-addon2"><i class="fas fa-search"></i></button>
										</div>
									</div>
								</div>
							</div>

							<!-- address -->
							<div class="col-md-7">
								<div class="form-group">
									<label class="form-control-label" for="input-address">Endererço</label>
									<input type="text" id="input-address" v-bind:class="{'is-invalid': errors.address}"   class="form-control" placeholder="Endereço" v-model="address" required="true" >
									<div class="invalid-feedback" v-if="errors.address">
										<p>{{ errors.address[0] }}</p>
									</div>
								</div>
							</div>

							<!-- number -->
							<div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-number">Nº</label>
									<input type="text" id="input-number" v-bind:class="{'is-invalid': errors.number}"  maxlength="50" class="form-control" placeholder="Nº" v-model="number" required="true" >
									<div class="invalid-feedback" v-if="errors.number">
										<p>{{ errors.number[0] }}</p>
									</div>
								</div>
							</div>

							<!-- complement -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-complement">Complemento</label>
									<input type="text" id="input-complement" v-bind:class="{'is-invalid': errors.complement}"  maxlength="100" class="form-control" placeholder="" v-model="complement" required="false" >
									<div class="invalid-feedback" v-if="errors.complement">
										<p>{{ errors.complement[0] }}</p>
									</div>
								</div>
							</div>

							<!-- neighborhood -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-neighborhood">Bairro</label>
									<input type="text" id="input-neighborhood" v-bind:class="{'is-invalid': errors.neighborhood}"   class="form-control" placeholder="Bairro" v-model="neighborhood" required="true" >
									<div class="invalid-feedback" v-if="errors.neighborhood">
										<p>{{ errors.neighborhood[0] }}</p>
									</div>
								</div>
							</div>

							<!-- state -->
							<div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-state">Estado</label>
									<v-select :options="states" label="Sigla" v-bind:class="{'is-invalid': errors.state}" v-model="state" :reduce="Sigla => Sigla.ID" @input="validateCities()"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.state">
										<p>{{ errors.state[0] }}</p>
									</div>
								</div>
							</div>

							<!-- city -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-city">Cidade</label>
									<v-select :options="cities" label="Nome" v-bind:class="{'is-invalid': errors.city}" :reduce="Nome => Nome.ID" v-model="city"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.city">
										<p>{{ errors.city[0] }}</p>
									</div>
								</div>
							</div>

						</div>

					</div>
				</div>
			</div>

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
	props: ["partners"],
	mixins: [ApiService],
		data() {
			return {
				name: this.getData("name"),
				cpf: this.getData("cpf"),
				address: this.getData("address"),
				number: this.getData("number"),
				complement: this.getData("complement"),
				neighborhood: this.getData("neighborhood"),
				city: this.getData("city"),
				state: this.getData("state"),
				cep: this.getData("zip_code"),
				phone: this.getData("phone"),
				whatsapp: this.getData("whatsapp"),
				birth_date: this.getData("birth_date"),
				total_balance: this.getData("total_balance"),
				errors: [],
				cities: [],
				states: [],
				tempCities: [],
				message: "",
				loading: false,
				fullPage: true
			}
		},
		mounted() {
			this.loadCities();
			this.loadStates();
		},
		methods: {
			getData(key) {
				return JSON.parse(this.partners)[key];
			},
			loadCities(){
        let _this = this;
        axios.get('/js/cities.min.json').then((res) => {
          _this.tempCities = res.data;
        }).catch((err) => {
          console.log(err);
        });
      },
      loadStates(){
        let _this = this;
        axios.get('/js/states.min.json').then((res) => {
          _this.states = res.data;
        }).catch((err) => {
          console.log(err);
        });
      },
			updateData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"name" : _this.name,
					"cpf" : _this.cpf,
					"address" : _this.address,
					"number" : _this.number,
					"complement" : _this.complement,
					"neighborhood" : _this.neighborhood,
					"city" : _this.city,
					"state" : _this.state,
					"cep" : _this.cep,
					"phone" : _this.phone,
					"whatsapp" : _this.whatsapp,
					"birth_date" : _this.birth_date,
					"_method": "PATCH"
				};
				axios.post("/admin/partners/"+this.getData('id'), dataInfo).then((res) => {

					_this.loading = false;

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,false);

				}).catch((err) => {

					_this.loading = false;

					if (err.response.data.errors){
						//set errors
						_this.errors = err.response.data.errors;
					}

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			searchCEP(){
				axios.get("https://viacep.com.br/ws/"+this.cep+"/json").then((res) => {
					this.address = res.data.logradouro;
					this.neighborhood = res.data.bairro;
					this.state = res.data.uf;
					this.validateCities();
					this.city = res.data.localidade;
				});
			},
			validateCities(){
        let _this = this;
        _this.cities = [];
        _this.tempCities.map((row) => {
          if (row.Estado == _this.state){
            _this.cities.push(row);
          }
        });
      }
		}
	}
</script>
