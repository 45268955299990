<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos vendedores cadastrados</p>
				<a href="/admin/sellers/create" class="btn btn-outline-success float-right"><i class="fas fa-plus"></i>&nbsp;Novo</a>
				<a class="btn btn-outline-warning float-right mr-4" data-toggle="collapse" href="#panelFilter" role="button" aria-expanded="false" aria-controls="panelFilter"><i class="fas fa-filter"></i>&nbsp;Filtrar</a>
			</div>
		</div>

		<!-- filter -->
		<div class="row">
			<div class="col-md-12">
				<div class="collapse" id="panelFilter">
					<div class="card card-body">
						<div class="row">

							<!-- indicated_by -->
							<div class="col-md-12 mt-2">
								<label class="form-control-label" for="input-product_id">Indicado por:</label>
								<v-select :options="sellersItems" v-model="filterItems.indicated" label="name" :reduce="name => name.id"></v-select>
							</div>
							
							<div class="col-md-12 text-right mt-2">
								<a href="#" class="btn btn-outline-secondary" style="margin-right: 20px;" @click="clearFilter()">Limpar</a>
								<button class="btn btn-success" @click="filter()">FILTRAR</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- grid list -->
		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="sellersItems.length"
						:columns="tableColumns"
						:rows="sellersItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum vendedor cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>
		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				tempSellersItems: [],
				sellersItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				filterItems: {
					indicated: ''
				},
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'Nome',
						field: 'name',
					},
					{
						label: 'Cidade',
						field: 'city',
					},
					{
						label: 'Estado',
						field: 'state',
					},
					{
						label: 'Telefone',
						field: 'phone',
					},
					{
						label: 'Whatsapp',
						field: 'whatsapp',
					},
					{
						label: 'Data de Nascimento',
						field: 'birth_date',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd",
						dateOutputFormat: 'dd/MM/yyyy'
					},
					{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},
					{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/sellers").then((res) => {
					_this.sellersItems = _this.sellersItems.concat(res.data);
					_this.tempSellersItems = _this.tempSellersItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			clearFilter(){
				this.sellersItems = [];
				this.sellersItems = this.tempSellersItems;
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Vendedor",
					text: "Tem certeza que deseja excluir esse Vendedor?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/sellers/"+ID).then((res) => {

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){
				location.href="/admin/sellers/"+item.id+"/edit";
			},
			filter(){
				var _this = this;

				//clear sellers
				_this.sellersItems = [];

				//read all sellers to filter
				_this.tempSellersItems.map((rowSeller) => {
					if(rowSeller.id == _this.filterItems.indicated){
						_this.sellersItems.push(rowSeller);
					}
				});

			}
		}
	}
</script>
