<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todas Notificações cadastradas</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalNewNotification"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="notificationsItems.length"
						:columns="tableColumns"
						:rows="notificationsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhuma notificação cadastrada!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalNewNotification" tabindex="-1" aria-labelledby="modalNewNotificationLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalNewNotificationLabel">{{modalTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

                  <!-- sellers -->
                  <div class="col-md-12" v-if="!isEditNotification">
                    <div class="form-group">
                      <label class="form-control-label" for="input-sellers">Selecione os Vendedores</label>
                      <v-select multiple :options="sellersOptions" label="name" :reduce="name => name.id" v-model="selectedSellers"></v-select>
                    </div>
                  </div>

                  <!-- clients -->
                  <div class="col-md-12" v-if="!isEditNotification">
                    <div class="form-group">
                      <label class="form-control-label" for="input-clients">Selecione os Clientes</label>
                      <v-select multiple :options="clientsOptions" label="name" :reduce="name => name.id" v-model="selectedClients"></v-select>
                    </div>
                  </div>

                  <!-- title -->
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="form-control-label" for="input-title">Título</label>
                      <input type="text" id="input-title" class="form-control" placeholder="Nome" v-model="title" required="true" >
                    </div>
                  </div>

                  <!-- message -->
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="form-control-label" for="input-message">Mensagem</label>
                      <textarea type="text" id="input-message" class="form-control" placeholder="Mensagem" v-model="message" required="true" ></textarea>
                    </div>
                  </div>

                </div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				modalTitle: "Nova Notificação",
        clientsOptions: [],
        sellersOptions: [],
				isEditNotification: false,
				notificationID: "",
				notificationsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'ID',
						field: 'id',
					},{
						label: 'Título',
						field: 'title',
					},{
						label: 'Mensagem',
						field: 'message',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
        tempColaborators: [],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
      this.loadSellers();
      this.loadClients();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/notifications").then((res) => {
					_this.notificationsItems = _this.notificationsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
      loadClients() {
				let _this = this;
				axios.get("/admin/clients").then((res) => {
					_this.clientsOptions = _this.clientsOptions.concat(res.data);
				}).catch((err) => {
				});
			},
      loadSellers() {
				let _this = this;
				axios.get("/admin/sellers").then((res) => {
					_this.sellersOptions = _this.sellersOptions.concat(res.data);
				}).catch((err) => {
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditNotification == true){

					let dataInfo = {
						"clients" : _this.selectedClients,
						"sellers" : _this.selectedSellers,
            "title" : _this.title,
            "message" : _this.message,
						"_method": "PATCH"
					};
					axios.post("/admin/notifications/"+_this.notificationID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalNewNotification').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//set errors
          	_this.errors = err.response.data.errors;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"clients" : _this.selectedClients,
						"sellers" : _this.selectedSellers,
            "title" : _this.title,
            "message" : _this.message,
						"last": ""
					};
					axios.post("/admin/notifications", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.notificationsItems.push(res.data.data);
						$('#modalNewNotification').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//set errors
          	_this.errors = err.response.data.errors;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Notificação",
					text: "Tem certeza que deseja excluir essa Notificação?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/notifications/"+ID).then((res) => {

							//_this.notificationsItems.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.isEditNotification = true;
				this.notificationID = item.id;
				this.modalTitle = "Editar Notificação";

				this.title = item.title;
				this.message = item.message;

				$("#modalNewNotification").modal('show');

			},
			resetForm(){

				this.selectedClients = [];
        this.selectedSellers = [];
        this.title = "";
        this.message = "";
				this.errors = [];

				this.isEditNotification = false;
				this.notificationID = "";
				this.modalTitle = "Nova Notificação";

			}
		}
	}
</script>
