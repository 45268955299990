<template>
  <section class="dashboard">

    <!-- widgets -->
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-primary">
            <i class="fas fa-chart-line"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header text-left">
              <h4>Vendas Diretas</h4>
            </div>
            <div class="card-body">
              0
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-success">
            <i class="fas fa-dollar-sign"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Vendas Direta (R$)</h4>
            </div>
            <div class="card-body">
              R$ 0,00
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-primary">
            <i class="fas fa-chart-line"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Vendas Indicação</h4>
            </div>
            <div class="card-body">
              0
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-success">
            <i class="fas fa-dollar-sign"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Vendas Indicação (R$)</h4>
            </div>
            <div class="card-body">
              R$ 0,00
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-primary">
            <i class="fas fa-user-friends"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Total Clientes</h4>
            </div>
            <div class="card-body">
              0
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-primary">
            <i class="fas fa-briefcase"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Novos Vendedores (Indicação)</h4>
            </div>
            <div class="card-body">
              0
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-success">
            <i class="fas fa-dollar-sign"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Total Pago Vendedores</h4>
            </div>
            <div class="card-body">
              R$ 0,00
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-warning">
            <i class="fas fa-dollar-sign"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Total Pendente Vendedores</h4>
            </div>
            <div class="card-body">
              R$ 0,00
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- infos -->
    <div class="row">
      
       <!-- chart -->
       <div class="col-md-8">
          <div class="card card-chart">
            <div class="card-header text-center text-primary d-block">
              Gráfico de Vendas
            </div>
            <div class="card-body">
              <p class="chart-empty">
                Não existem dados
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card card-chart">
            <div class="card-header text-center text-primary d-block">
              Produtos com Baixo Estoque
            </div>
            <div class="card-body">
              <p class="chart-empty">
                Nenhum Produto
              </p>
            </div>
          </div>
        </div>

    </div>

  </section>
</template>
<script>
  export default {
    mounted() {
      
    }};
</script>