<template>
	<div class="tab-pane fade" id="tab-productattachments" role="tabpanel" aria-labelledby="productattachments-tab">

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos  cadastrados</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalProductAttachments"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="productattachmentsItems.length"
						:columns="tableColumns"
						:rows="productattachmentsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum  cadastrado(a)!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalProductAttachments" tabindex="-1" aria-labelledby="modalProductAttachmentsLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalProductAttachmentsLabel">{{productattachmentsTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

							<!-- type -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-type">Tipo</label>
								<v-select v-model="productattachment.type"></v-select>
									<div class="invalid-feedback" v-if="errors.type">
										<p>{{ errors.type[0] }}</p>
									</div>
								</div>
							</div>
							

							<!-- file -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-file">Arquivo</label>
									<div class="custom-file">
										<input type="file" class="custom-file-input" id="file" name="file" accept="image/*" ref="file" lang="pt" v-on:change="handleFilefileUpload()">
										<label class="custom-file-label" for="file">{{filefileName}}</label>
									</div>
									<div class="invalid-feedback" v-if="errors.file">
										<p>{{ errors.file[0] }}</p>
									</div>
								</div>
							</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SAVE</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				filefileName: "Selecione o arquivo",
				productattachmentsTitle: "Novo ",
				productattachment: {
					type: '',
					file: '',
				},
				isEditProductAttachments: false,
				productattachmentsID: "",
				productattachmentsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'type',
						field: 'type',
					},
					{
						label: 'file',
						field: 'file',
					},
					{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},
					{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/productattachments").then((res) => {
					_this.productattachmentsItems = _this.productattachmentsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditProductAttachments == true){

					let dataInfo = {
						"type" : _this.productattachment.type,
						"file" : _this.productattachment.file,
						"_method": "PATCH"
					};
					axios.post("/admin/productattachments/"+_this.productattachmentsID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalProductAttachments').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"type" : _this.productattachment.type,
						"file" : _this.productattachment.file,
						"last": ""
					};
					axios.post("/admin/productattachments", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.productattachmentsItems.push(res.data.data);
						$('#modalProductAttachments').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir productattachments",
					text: "Tem certeza que deseja excluir esse(a) productattachments?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/productattachments/"+ID).then((res) => {

							_this.productattachments.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.productattachment = item;
				this.isEditProductAttachments = true;
				this.productattachmentsID = item.id;
				this.productattachmentsTitle = "Edit ";

				$("#modalProductAttachments").modal('show');

			},
			resetForm(){

				this.productattachment = {
					type: '',
					file: '',
				};

					this.errors = [];

				this.productattachment = "";
				this.isEditProductAttachments = false;
				this.productattachmentsID = "";
				this.productattachmentsTitle = "New ";

			}
		}
	}
</script>
