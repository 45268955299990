<template>
	<div>
		<div class="row">
			<div class="col-12">
				<div class="card">

					<!-- card header -->
					<!-- card header -->
					<div class="card-header">
						<h4>Confira os dados abaixo</h4>

						<a @click="updateData" class="btn btn-sm btn-success text-white btn-right">ATUALIZAR</a>
					</div>
					<!-- end card header -->

					<!-- card body -->
					<div class="card-body">
						<div class="row">

							<!-- status -->
							<div class="col-md-8">
								<div class="form-group">
									<label class="form-control-label" for="input-product_id">Status</label>
									<v-select :options="statusOptions" v-model="status"></v-select>
								</div>
							</div>

							<!-- product_id -->
							<!-- <div class="col-md-8">
								<div class="form-group">
									<label class="form-control-label" for="input-product_id">Produto</label>
									<v-select :options="productsOptions" label="title" v-model="product_id" :reduce="title => title.id" @input="validateProduct()" :disabled="true"></v-select>
									<div class="invalid-feedback" v-if="errors.product_id">
										<p>{{ errors.product_id[0] }}</p>
									</div>
								</div>
							</div> -->

							<!-- amount -->
							<!-- <div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-amount">Quantidade</label>
									<input type="number" id="input-amount" v-bind:class="{'is-invalid': errors.amount}"   class="form-control" placeholder="Quantidade" v-model="amount" required="true" @change="calculateSummary()">
									<div class="invalid-feedback" v-if="errors.amount">
										<p>{{ errors.amount[0] }}</p>
									</div>
								</div>
							</div> -->

							<!-- source -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-type">Tipo</label>
									<v-select :options="sourceOptions" label="label" v-model="source" :reduce="label => label.id" :disabled="true"></v-select>
								</div>
							</div>

						</div>

						<div class="row">

							<!-- client_id -->
							<div class="col-md-12" v-if="source == 'client'">
								<div class="form-group">
									<label class="form-control-label" for="input-client_id">Cliente</label>
									<v-select :options="clientsOptions" label="name" v-model="client_id" :reduce="name => name.id" :disabled="true"></v-select>
									<div class="invalid-feedback" v-if="errors.client_id">
										<p>{{ errors.client_id[0] }}</p>
									</div>
								</div>
							</div>

							<!-- seller_id -->
							<div class="col-md-12" v-if="source == 'seller'">
								<div class="form-group">
									<label class="form-control-label" for="input-client_id">Vendedor</label>
									<v-select :options="sellersOptions" label="name" v-model="seller_id" :reduce="name => name.id" :disabled="true"></v-select>
									<div class="invalid-feedback" v-if="errors.seller_id">
										<p>{{ errors.seller_id[0] }}</p>
									</div>
								</div>
							</div>

							</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-8">

				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Entrega / Retirada</h6>
						<div class="row">

							<!-- delivery_type -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-delivery_type">Tipo de Entrega</label>
									<v-select :options="shippingOptions" label="label" v-model="delivery_type" :reduce="label => label.id"></v-select>
									<div class="invalid-feedback" v-if="errors.delivery_type">
										<p>{{ errors.delivery_type[0] }}</p>
									</div>
								</div>
							</div>

							<div class="col-md-7" v-if="delivery_type !== 'to_remove'">
								<div class="form-group input-group-merge">
									<label class="form-control-label" for="input-cep">CEP</label>
									<div class="input-group">
										<input type="text" v-bind:class="{'is-invalid': errors.cep}"  maxlength="20" class="form-control" placeholder="CEP" v-model="cep" required="true">
										<div class="input-group-append">
											<button class="btn btn-outline-secondary" @click="searchCEP()" type="button" id="button-addon2"><i class="fas fa-search"></i></button>
										</div>
									</div>
								</div>
							</div>

						</div>

					</div>
				</div>

				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Pagamento / Desconto</h6>
						<div class="row">

							<!-- payment_type -->
							<div class="col-md-8">
								<div class="form-group">
									<label class="form-control-label" for="input-payment_type">Tipo de Pagamento</label>
									<v-select :options="paymentOptions" label="label" v-model="payment_type" :reduce="label => label.id"></v-select>
									<div class="invalid-feedback" v-if="errors.payment_type">
										<p>{{ errors.payment_type[0] }}</p>
									</div>
								</div>
							</div>

							<!-- coupon -->
							<!-- <div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-coupon">Cupom</label>
									<input type="text" id="input-coupon" v-bind:class="{'is-invalid': errors.coupon}"   class="form-control" placeholder="Cupom" v-model="coupon" required="false" >
									<div class="invalid-feedback" v-if="errors.coupon">
										<p>{{ errors.coupon[0] }}</p>
									</div>
								</div>
							</div> -->

							<!-- discount -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-discount">Desconto</label>
									<div class="input-group mb-3">
										<div class="input-group-prepend">
											<span class="input-group-text">R$</span>
										</div>
										<input type="number" id="input-discount" v-bind:class="{'is-invalid': errors.discount}"   class="form-control" placeholder="Desconto" v-model="discount" @change="calculateSummary()">
									</div>
									<div class="invalid-feedback" v-if="errors.discount">
										<p>{{ errors.discount[0] }}</p>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

			</div>

			<div class="col-md-4">
				
				<!-- <div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Produto</h6>
						<div class="row">
							<img v-if="selectedProduct.attachments.length > 0" :src="'/storage/products/'+selectedProduct.attachments[0].file" style="width: 100%;"/>
						</div>
					</div>
				</div> -->

				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Resumo</h6>

						<div class="row">
							<div class="col-md-6">
								<p>Valor Unitário Produto:</p>
							</div>
							<div class="col-md-6">
								<p><b>R$ {{ _formatPrice(this.summary.unitary_value) }}</b></p>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<p>Quantidade:</p>
							</div>
							<div class="col-md-6">
								<p><b>{{parseInt(this.summary.amount)}}</b></p>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<p>Subtotal:</p>
							</div>
							<div class="col-md-6">
								<p><b>R$ {{ _formatPrice(this.summary.subtotal) }}</b></p>
							</div>
						</div>

						<div class="row mt-4">
							<div class="col-md-6">
								<p>Frete / Entrega:</p>
							</div>
							<div class="col-md-6">
								<p><b>R$ {{ _formatPrice(this.summary.shipping) }}</b></p>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<p>Desconto:</p>
							</div>
							<div class="col-md-6">
								<p><b>R$ {{ _formatPrice(this.summary.discount) }}</b></p>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<p>Total:</p>
							</div>
							<div class="col-md-6">
								<p><b>R$ {{ _formatPrice(this.summary.total) }}</b></p>
							</div>
						</div>

					</div>
				</div>

			</div>

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>

		<div class="row">
			<div class="col-md-12">

				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Produtos</h6>

						<!-- head -->
						<div class="row">
							<div class="col-md-1"></div>
							<div class="col-md-5">Descrição</div>
							<div class="col-md-2">Qtd</div>
							<div class="col-md-2">Valor Unit.</div>
							<div class="col-md-2">Total Produto</div>
						</div>

						<!-- unique product -->
						<div class="row mt-4" v-if="product_id !== null">
							<div class="col-md-1">
								<img :src="'/storage/products/'+getProductInfo(product_id,'image')" style="width: 60%;margin-top: -15px;"/>
							</div>
							<div class="col-md-5">
								{{ getProductInfo(product_id, 'title') }}
							</div>
							<div class="col-md-2">
								{{ summary.amount }}
							</div>
							<div class="col-md-2">
								R$ {{ summary.unitary_value.toFixed(2) }}
							</div>
							<div class="col-md-2">
								R$ {{ summary.subtotal.toFixed(2) }}
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import utilsService from '../../utils.js';
	export default {
		props: ["sales"],
		mixins: [ApiService, utilsService],
		data() {
			return {
				amount: this.getData("amount"),
				cep: '',
				subtotal: this.getData("subtotal"),
				delivery_type: this.getData("delivery_type"),
				delivery_discount: this.getData("delivery_discount"),
				coupon: this.getData("coupon"),
				coupon_discount: this.getData("coupon_discount"),
				discount: this.getData("discount") == null ? 0 : this.getData("discount"),
				total: this.getData("total"),
				payment_type: this.getData("payment_type"),
				seller_id: this.getData("seller_id"),
				sellersOptions: [],
				client_id: this.getData("client_id"),
				clientsOptions: [],
				paymentOptions: [{
					id: 'pix',
					label: "Pix - Manual"
				},{
					id: 'card',
					label: "Cartão - Manual"
				},{
					id: 'credit-card',
					label: "Cartão de Crédito"
				}],
				product_id: this.getData("product_id"),
				productsOptions: [],
				selectedProduct: {
					attachments: []
				},
				shippingOptions: [{
					id: 'delivery',
					label: "Entregar"
				},{
					id: 'to_remove',
					label: "Retirar"
				}],
				source: "client",
				sourceOptions: [{
					id: 'client',
					label: "Cliente"
				},{
					id: 'seller',
					label: "Vendedor"
				}],
				status: this.validateStatus(),
				statusOptions: ["Novo Pedido","Aguardando Pagamento","Pagamento Confirmado","Aguardando Envio","Enviado","Concluído"],
				summary: {
					unitary_value: 0,
					amount: 0,
					subtotal: 0,
					shipping: 0,
					coupon: 0,
					discount: 0,
					total: 0
				},
				errors: [],
				message: "",
				loading: false,
				fullPage: true
			}
		},
		mounted() {
			this.loadSellers();
			this.loadClients();
			this.loadProducts();
		},
		methods: {
			getData(key) {
				return JSON.parse(this.sales)[key];
			},
			updateData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"amount" : _this.amount,
					"subtotal" : _this.subtotal,
					"delivery_type" : _this.delivery_type,
					"delivery_discount" : _this.delivery_discount,
					"coupon" : _this.coupon,
					"coupon_discount" : _this.coupon_discount,
					"discount" : _this.discount,
					"total" : _this.total,
					"payment_type" : _this.payment_type,
					"seller_id" : _this.seller_id,
					"client_id" : _this.client_id,
					"product_id" : _this.product_id,
					"_method": "PATCH"
				};
				axios.post("/admin/sales/"+this.getData('id'), dataInfo).then((res) => {

					_this.loading = false;

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,true);

				}).catch((err) => {

					_this.loading = false;

					if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

				});
			},
			loadSellers() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/sellers").then((res) => {
					_this.sellersOptions = _this.sellersOptions.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadClients() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/clients").then((res) => {
					_this.clientsOptions = _this.clientsOptions.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadProducts() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/products").then((res) => {
					_this.productsOptions = _this.productsOptions.concat(res.data);
					_this.loading = false;
					_this.validateProduct();
				}).catch((err) => {
					_this.loading = false;
				});
			},
			calculateSummary(){

				//validate discount
				if(this.discount > this.summary.total){

					this.$swal({
						title: 'Atenção',
						text: 'Valor do Desconto não pode ser maior que o Valor Total da Venda',
						icon: 'warning'
					});
					this.discount = "";

				} else {

					//validate unitary value
					this.selectedProduct.prices.map((rowPrice) => {
						if(this.amount >= rowPrice.amount_start && this.amount <= rowPrice.amount_end){
							this.summary.unitary_value = rowPrice.value.toFixed(2);
							console.log(rowPrice.value);
						}
					});

					this.summary.discount = this.discount;
					this.summary.amount = this.amount;
					this.summary.subtotal = this.summary.amount * this.summary.unitary_value;

					this.summary.total = (this.summary.subtotal + this.summary.shipping) - this.summary.coupon - this.summary.discount;

				}

			},
			getProductInfo(id,item){
				var returnInfo;

				//read all product to get info
				this.productsOptions.map((rowProduct) => {
					if(rowProduct['id'] === id){
						if(item == 'image'){
							if(rowProduct.attachments.length > 0){
								returnInfo = rowProduct.attachments[0].file;
							}
						} else {
							returnInfo = rowProduct[item];
						}
					}
				});

				return returnInfo;

			},
			validateProduct(){
				//search product info
				this.productsOptions.map((rowProduct) => {
					if(rowProduct.id == this.product_id){
						this.selectedProduct = rowProduct;
						this.calculateSummary();
					}
				});
			},
			validateStatus(){
				var tempStatus = this.getData("status");
				return tempStatus[0].status;
			}
		}
	}
</script>
