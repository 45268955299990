<template>
  <div class="col-12 pad0">
    <div class="card">
      
      <!-- card header -->
      <div class="card-header">
        <h4>Preencha os dados abaixo</h4>

        <a @click="updateData" class="btn btn-sm btn-success text-white btn-right">ATUALIZAR</a>
      </div>
      <!-- end card header -->

      <!-- card body -->
      <div class="card-body">

        <!-- form -->
        <h6 class="heading-small text-muted mb-4">Dados Básicos</h6>
        <div class="pl-lg-4">
          <div class="row">

            <!-- name -->
            <div class="col-12">
              <div class="form-group">
                <label class="form-control-label">Nome</label>
                <input type="text" id="name" v-bind:class="{'is-invalid': errors.name}" class="form-control" placeholder="Nome" v-model="name">
                <div class="invalid-feedback" v-if="errors.name">
                    <p>{{ errors.name[0] }}</p>
                </div>
              </div><!-- form group -->
            </div><!-- col-lg-12 -->
            <!-- end name -->

          </div><!-- row -->
        </div>

        <!-- divider -->
        <hr class="my-4" />

        <!-- Access -->
        <h6 class="heading-small text-muted mb-4">Acesso</h6>
        <div class="pl-lg-4">
          <div class="row">
            
            <!-- email -->
            <div class="col-6">
              <div class="form-group">
                <label class="form-control-label">E-mail</label>
                <input type="email" id="email" v-bind:class="{'is-invalid': errors.email}" class="form-control" placeholder="E-mail" v-model="email" disabled>
                <div class="invalid-feedback" v-if="errors.email">
                    <p>{{ errors.email[0] }}</p>
                </div>
              </div><!-- form group -->
            </div><!-- col-lg-12 -->
            <!-- end email -->

            <!-- password -->
            <div class="col-6">
              <div class="form-group">
                <label class="form-control-label">Senha</label>
                <input type="password" id="password" v-bind:class="{'is-invalid': errors.password}" class="form-control" placeholder="Digite a senha somente se for alterar!" v-model="password">
                <div class="invalid-feedback" v-if="errors.password">
                    <p>{{ errors.password[0] }}</p>
                </div>
              </div><!-- form group -->
            </div><!-- col-lg-12 -->
            <!-- end password -->

          </div>
        </div>         
        <!-- end form -->

      </div><!-- card body -->
    </div><!-- card -->

    <vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
  </div><!-- col-12 -->
</template>

<script>
  import ApiService from '../../apiservice.js';
  export default {
    props: ['auth'],
    mixins: [ApiService],
    data() {
      return {
        name: this.getData('profile').name,
        email: this.getData('email'),
        password: '',
        errors: [],
        message: '',
        loading: false,
        fullPage: true
      }
    },
    mounted() {
    },
    methods: {
      getData(key) {
        return JSON.parse(this.auth)[key];
      },
      updateData() {
        let _this = this;
        _this.errors = [];
        _this.loading = true;
        let dataUser = {
          'name': _this.name,
          'email': _this.email,
          'password': _this.password
        };
        axios.post('/admin/account/profile/update', dataUser).then((res) => { 

          _this.loading = false;

          //call function in ApiService to validate success
          _this._api_validateSuccess(res,false);

        }).catch((err) => {

          _this.loading = false;

          //call function in ApiService to validate error
          _this._api_validateError(err);

        });
      }
    }
  }
</script>
