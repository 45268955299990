<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Conteúdos cadastrados</p>
				<a href="/admin/contents/create" class="btn btn-outline-success float-right"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="contentsItems.length"
						:columns="tableColumns"
						:rows="contentsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_image'">
								<img :src="'/storage/contents/images/'+props.row.image" style="width: 100px;height: 100px;object-fit: contain;"/>
							</span>
							<span v-if="props.column.field == '_views'">
								0
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum conteúdo cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>
		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				contentsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: '#',
						field: '_image',
					},
					{
						label: 'Título',
						field: 'title',
					},
					{
						label: 'Visualizacões',
						field: '_views',
					},
					{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},
					{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/contents").then((res) => {
					_this.contentsItems = _this.contentsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Conteúdo",
					text: "Tem certeza que deseja excluir esse Conteúdo?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/contents/"+ID).then((res) => {

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){
				location.href="/admin/contents/"+item.id+"/edit";
			}
		}
	}
</script>
