<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Configurações cadastrados</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalConfigurations"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="configurationsItems.length"
						:columns="tableColumns"
						:rows="configurationsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_type'">
								<div v-if="props.row.type == 'system'">Sistema</div>
								<div v-if="props.row.type == 'sale_shipping'">Venda (Frete)</div>
								<div v-if="props.row.type == 'sale_payment'">Venda (Pagamento)</div>
								<div v-if="props.row.type == 'others'">Outros</div>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit && props.row.name !== 'Plataforma Entrega'" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete && props.row.type !== 'system'" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhuma configuração cadastrada!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalConfigurations" tabindex="-1" aria-labelledby="modalConfigurationsLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalConfigurationsLabel">{{configurationsTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

							<!-- name -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-name">Nome</label>
									<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}"   class="form-control" placeholder="Nome" v-model="configuration.name" required="true" >
									<div class="invalid-feedback" v-if="errors.name">
										<p>{{ errors.name[0] }}</p>
									</div>
								</div>
							</div>
							

							<!-- type -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-type">Tipo</label>
									<!-- <input type="text" id="input-type" v-bind:class="{'is-invalid': errors.type}"   class="form-control" placeholder="Tipo" v-model="configuration.type" required="true" > -->
									<v-select :options="typeOptions" label="label" v-bind:class="{'is-invalid': errors.type}" :reduce="label => label.id" v-model="configuration.type"></v-select>
									<div class="invalid-feedback" v-if="errors.type">
										<p>{{ errors.type[0] }}</p>
									</div>
								</div>
							</div>
							

							<!-- content -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-content">Conteúdo</label>
									<textarea type="text" id="input-content" v-bind:class="{'is-invalid': errors.content}" class="form-control" placeholder="Conteúdo" v-model="configuration.content" rows="8" required="true"></textarea>
									<div class="invalid-feedback" v-if="errors.content">
										<p>{{ errors.content[0] }}</p>
									</div>
								</div>
							</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				configurationsTitle: "Nova Configuração",
				configuration: {
					name: '',
					type: '',
					content: '',
				},
				isEditConfigurations: false,
				configurationsID: "",
				configurationsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'Nome',
						field: 'name',
					},{
						label: 'Tipo',
						field: '_type',
					},{
						label: 'Conteúdo',
						field: 'content',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				typeOptions:[{
					id: 'system',
					label: 'Sistema'
				},{
					id: 'sale_shipping',
					label: 'Venda (Entrega)'
				},{
					id: 'sale_payment',
					label: 'Venda (Pagamento)'
				},{
					id: 'others',
					label: 'Outros'
				}],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/configurations").then((res) => {
					_this.configurationsItems = _this.configurationsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditConfigurations == true){

					let dataInfo = {
						"name" : _this.configuration.name,
						"type" : _this.configuration.type,
						"content" : _this.configuration.content,
						"_method": "PATCH"
					};
					axios.post("/admin/configurations/"+_this.configurationsID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalConfigurations').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"name" : _this.configuration.name,
						"type" : _this.configuration.type,
						"content" : _this.configuration.content,
						"last": ""
					};
					axios.post("/admin/configurations", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.configurationsItems.push(res.data.data);
						$('#modalConfigurations').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Configuração",
					text: "Tem certeza que deseja excluir essa Configuração",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/configurations/"+ID).then((res) => {

							_this.configurations.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.configuration = item;
				this.isEditConfigurations = true;
				this.configurationsID = item.id;
				this.configurationsTitle = "Editar Configuração";

				$("#modalConfigurations").modal('show');

			},
			resetForm(){

				this.configuration = {
					name: '',
					type: '',
					content: '',
				};

				this.errors = [];

				this.isEditConfigurations = false;
				this.configurationsID = "";
				this.configurationsTitle = "Nova Configuração";

			}
		}
	}
</script>
