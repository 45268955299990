<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todo Histórico de Saldo</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalManageBalance"><i class="fas fa-plus"></i>&nbsp;Gerenciar Saldo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="balanceItems.length"
						:columns="tableColumns"
						:rows="balanceItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == '_type'" :class="props.row.type == 0 ? 'text-success' : 'text-danger'">
                <div v-if="props.row.type == 0">Entrada</div>
                <div v-if="props.row.type == 1">Saída</div>
              </span>
              <span v-if="props.column.field == '_amount'" :class="props.row.type == 0 ? 'text-success' : 'text-danger'">
                R$ {{ _formatPrice(props.row.amount) }}
              </span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhuma histórico de saldo encontrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalManageBalance" tabindex="-1" aria-labelledby="modalManageBalanceLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalManageBalanceLabel">Gerenciar Saldo</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- to -->
									<div class="col-md-12">
                    <div class="form-group">
                      <label class="form-control-label" for="input-sellers">Para quem será?</label>
                      <v-select :options="toOptions" label="label" :reduce="label => label.id" v-model="selectedTo" required="true" ></v-select>
                    </div>
                  </div>

                  <!-- sellers -->
                  <div class="col-md-12" v-if="selectedTo == 'seller'">
                    <div class="form-group">
                      <label class="form-control-label" for="input-sellers">Selecione o Vendedor</label>
                      <v-select :options="sellersOptions" label="name" :reduce="name => name.id" v-model="selectedSeller" required="true" ></v-select>
                    </div>
                  </div>

									<!-- partners -->
									<div class="col-md-12" v-if="selectedTo == 'partner'">
                    <div class="form-group">
                      <label class="form-control-label" for="input-sellers">Selecione o Parceiro</label>
                      <v-select :options="partnerOptions" label="name" :reduce="name => name.id" v-model="selectedPartner" required="true" ></v-select>
                    </div>
                  </div>

                  <!-- type -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label" for="input-type">Tipo</label>
                      <v-select :options="typeOptions" label="label" :reduce="label => label.id" v-model="type" required="true"></v-select>
                    </div>
                  </div>

                  <!-- amount -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label" for="input-amount">Valor</label>
                      <input type="number" id="input-amount" class="form-control" placeholder="Valor" v-model="amount" required="true" >
                    </div>
                  </div>

                  <!-- observations -->
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="form-control-label" for="input-observations">Observações</label>
                      <textarea type="text" id="input-observations" class="form-control" placeholder="Observações" v-model="observations"></textarea>
                    </div>
                  </div>

                </div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">REGISTRAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import utilsService from '../../utils.js';
	export default {
		mixins: [ApiService, utilsService],
		props: ['permissions'],
		data() {
			return {
				partnerOptions: [],
        sellersOptions: [],
        selectedSeller: "",
				selectedPartner: "",
				selectedTo: 'seller',
        type: 0,
        amount: 0,
        typeOptions: [{
          id: 0,
          label: "Entrada"
        },{
          id: 1,
          label: "Saída"
        }],
				toOptions: [{
					id: 'partner',
          label: "Parceiro"
				},{
					id: 'seller',
          label: "Vendedor"
				}],
        observations: '',
				balanceItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'ID',
						field: 'id',
					},{
						label: 'Usuário',
						field: 'user.profile.name',
					},{
						label: 'Tipo',
						field: '_type',
					},{
						label: 'Valor',
						field: '_amount',
					},{
						label: 'Observações',
						field: 'observations',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
      this.loadSellers();
			this.loadPartners();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/balances").then((res) => {
					_this.balanceItems = _this.balanceItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadPartners() {
				let _this = this;
				axios.get("/admin/partners").then((res) => {
					_this.partnerOptions = _this.partnerOptions.concat(res.data);
				}).catch((err) => {
				});
			},
      loadSellers() {
				let _this = this;
				axios.get("/admin/sellers").then((res) => {
					_this.sellersOptions = _this.sellersOptions.concat(res.data);
				}).catch((err) => {
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

        let dataInfo = {
          "seller" : _this.selectedSeller,
					"partner": _this.selectedPartner,
					"to": _this.selectedTo,
          "type" : _this.type,
          "amount" : _this.amount,
          "observations" : _this.observations,
          "last": ""
        };
        axios.post("/admin/balances", dataInfo).then((res) => {

          _this.loading = false;
          _this.resetForm();

          _this.balanceItems.push(res.data.data);
          $('#modalManageBalance').modal('hide');

          //call function in ApiService to validate success
          _this._api_validateSuccess(res,true);

        }).catch((err) => {

          _this.loading = false;

          //set errors
          _this.errors = err.response.data.errors;

          //call function in ApiService to validate error
          _this._api_validateError(err);

        });
			},
			resetForm(){

        this.selectedSeller = "";
				this.selectedPartner = "";
        this.type = 0;
        this.amount = 0;
        this.observations = "";
				this.errors = [];
			}
		}
	}
</script>
