<template>
	<div class="row access-groups">
		<div class="col">

			<!-- top -->
			<div class="row section-top">
				<div class="col-md-12 pad0">
					<p class="section-lead">Preencha os campos abaixo</p>
					<a href="#" @click="newData()" class="btn btn-primary float-right">SALVAR</a>
					<a href="#" @click="type = 'menu'" class="btn btn-secondary float-right" style="margin-right: 15px;" v-if="type != 'menu'">VER MENU</a>
				</div>
			</div>
			<!-- end top -->

			<!-- name -->
			<div class="card">
				<div class="card-body">
					<div class="form-group">
						<label>Nome do Grupo de Acesso</label>
						<input class="form-control" v-model="name" id="name"/>
					</div>
				</div>
			</div>
			<!-- end name -->

			<!-- menu -->
			<div class="row menu" v-if="type == 'menu'">
				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-cog"></i>
						</div>
						<div class="card-body">
							<h4>Painel Administrativo</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'admin'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-cog"></i>
						</div>
						<div class="card-body">
							<h4>Sistema</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'system'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>
			</div>
			<!-- end menu -->

			<!-- itens admin -->
			<div class="row list" v-if="type == 'admin'">

				<!-- list all admin permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.admin" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all admin permissions -->

			</div>
			<!-- end itens admin -->

			<!-- itens system -->
			<div class="row list" v-if="type == 'system'">

				<!-- list all system permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.system" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all system permissions -->

			</div>
			<!-- end itens system -->

		</div>

		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
	</div><!-- row -->
</template>

<script>
	import ApiService from "../../apiservice.js";
	export default {
		mixins: [ApiService],
		data() {
			return {
				loading: false,
				name: "",
				permission: {
					admin: [
						{
							title: "Grupos de Acesso",
							items: [
								{
									title: "Listar",
									name: "admin-permissions-list"
								},{
									title: "Criar",
									name: "admin-permissions-create"
								},{
									title: "Editar",
									name: "admin-permissions-edit"
								},{
									title: "Deletar",
									name: "admin-permissions-delete"
								}
							]
						},{
							title: "Usuários",
							items: [
								{
									title: "Listar",
									name: "admin-users-list"
								},{
									title: "Criar",
									name: "admin-users-create"
								},{
									title: "Editar",
									name: "admin-users-edit"
								},{
									title: "Deletar",
									name: "admin-users-delete"
								},{
									title: "Desativar",
									name: "admin-users-disable"
								},{
									title: "Resetar Senha",
									name: "admin-users-resetpassword"
								}
							]
						},{
							title: "Logs",
							items: [
								{
									title: "Log Acesso",
									name: "admin-logs-access"
								},{
									title: "Log API",
									name: "admin-logs-api"
								},{
									title: "Log Atividade",
									name: "admin-logs-activity"
								},{
									title: "Log Erro",
									name: "admin-logs-error"
								},{
									title: "Log de Falhas",
									name: "admin-logs-failedjobs"
								}
							]
						}
					],
					system: [
						{
							title: "Vendas",
							items: [
								{
									title: "Listar",
									name: "admin-sales-list"
								},{
									title: "Criar",
									name: "admin-sales-create"
								},{
									title: "Editar",
									name: "admin-sales-edit"
								},{
									title: "Deletar",
									name: "admin-sales-delete"
								}
							]
						},
						{
							title: "Clientes",
							items: [
								{
									title: "Listar",
									name: "admin-clients-list"
								},{
									title: "Criar",
									name: "admin-clients-create"
								},{
									title: "Editar",
									name: "admin-clients-edit"
								},{
									title: "Deletar",
									name: "admin-clients-delete"
								}
							]
						},
						{
							title: "Vendedores",
							items: [
								{
									title: "Listar",
									name: "admin-sellers-list"
								},{
									title: "Criar",
									name: "admin-sellers-create"
								},{
									title: "Editar",
									name: "admin-sellers-edit"
								},{
									title: "Deletar",
									name: "admin-sellers-delete"
								}
							]
						},
						{
							title: "Produtos",
							items: [
								{
									title: "Listar",
									name: "admin-products-list"
								},{
									title: "Criar",
									name: "admin-products-create"
								},{
									title: "Editar",
									name: "admin-products-edit"
								},{
									title: "Deletar",
									name: "admin-products-delete"
								}
							]
						},
						{
							title: "Produtos - Anexos",
							items: [
								{
									title: "Listar",
									name: "admin-productattachments-list"
								},{
									title: "Criar",
									name: "admin-productattachments-create"
								},{
									title: "Editar",
									name: "admin-productattachments-edit"
								},{
									title: "Deletar",
									name: "admin-productattachments-delete"
								}
							]
						},
						{
							title: "Configurações",
							items: [
								{
									title: "Listar",
									name: "admin-configurations-list"
								},{
									title: "Criar",
									name: "admin-configurations-create"
								},{
									title: "Editar",
									name: "admin-configurations-edit"
								},{
									title: "Deletar",
									name: "admin-configurations-delete"
								}
							]
						},
						{
							title: "Níveis",
							items: [
								{
									title: "Listar",
									name: "admin-comissions-list"
								},{
									title: "Criar",
									name: "admin-comissions-create"
								},{
									title: "Editar",
									name: "admin-comissions-edit"
								},{
									title: "Deletar",
									name: "admin-comissions-delete"
								}
							]
						},{
							title: "Notificações",
							items: [
								{
									title: "Listar",
									name: "admin-notifications-list"
								},{
									title: "Criar",
									name: "admin-notifications-create"
								},{
									title: "Editar",
									name: "admin-notifications-edit"
								},{
									title: "Deletar",
									name: "admin-notifications-delete"
								}
							]
						},{
							title: "Parceiros",
							items: [
								{
									title: "Listar",
									name: "admin-partners-list"
								},{
									title: "Criar",
									name: "admin-partners-create"
								},{
									title: "Editar",
									name: "admin-partners-edit"
								},{
									title: "Deletar",
									name: "admin-partners-delete"
								}
							]
						},{
							title: "Conteúdos",
							items: [
								{
									title: "Listar",
									name: "admin-contents-list"
								},{
									title: "Criar",
									name: "admin-contents-create"
								},{
									title: "Editar",
									name: "admin-contents-edit"
								},{
									title: "Deletar",
									name: "admin-contents-delete"
								}
							]
						},{
							title: "Saldo Financeiro",
							items: [
								{
									title: "Listar",
									name: "admin-balances-list"
								},{
									title: "Gerenciar",
									name: "admin-balances-manage"
								}
							]
						}
					]
				},
				permissions: [],
				type: "menu",
			}
		},
		mounted() {
		},
		methods: {
			changeToggle(toggle){

				//validate if have permissions
				if (this.permissions.length == 0){

					//push permission
					this.permissions.push(toggle);

				} else {

					//check if permission exists in array
					var check = this.permissions.includes(toggle);

					//validate check
					if (check){

						//remove permission
						this.permissions.pop(toggle);

					} else {

						//add permission
						this.permissions.push(toggle);

					}
					//end if validate check

				}
				//end validate if have permissions

			},
			newData() {
				if (this.name != ""){

					let _this = this;
					_this.errors = [];
					_this.message = "";
					_this.loading = true;

					let dataPermission = {
						"name": _this.name,
						"permission": _this.permission,
						"permissions": _this.permissions
					};
					axios.post("/admin/permissions", dataPermission).then((res) => {

						_this.loading = false;

						//call function in ApiService to validate success";
						_this._api_validateSuccess(res);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			}
		}
	}
</script>