require('./bootstrap');

window.Vue = require('vue');

//ALERT
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

//CKeditor
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use( CKEditor );

//LOADING
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
Vue.component('vue-loading', Loading);

//MASK
import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

//MOMENT
Vue.use(require('vue-moment'));

//VUE-GOOD-TABLE
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

//VUE SELECT
import vSelect from 'vue-select'
Vue.component('v-select', vSelect);
import 'vue-select/dist/vue-select.css';

//V-money
import money from 'v-money';
Vue.use(money, {precision: 4});


//COMPONENTS
//dashboard
import adminWidgetsComponent from './components/admin/dashboard/widgets';
Vue.component('admin-widgets-component', adminWidgetsComponent);

//logs
import LogAccessComponent from './components/admin/logs/access';
import LogActivityComponent from './components/admin/logs/activity';
import LogErrorComponent from './components/admin/logs/error';
Vue.component('logaccess-component',LogAccessComponent);
Vue.component('logactivity-component',LogActivityComponent);
Vue.component('logerror-component',LogErrorComponent);

//permissions
import permissionAddComponent from './components/admin/permissions/add';
import permissionEditComponent from './components/admin/permissions/edit';
import permissionListComponent from './components/admin/permissions/list';
Vue.component('permissions-add-component', permissionAddComponent);
Vue.component('permissions-edit-component', permissionEditComponent);
Vue.component('permissions-list-component', permissionListComponent);

//users
import userListComponent from './components/admin/users/list';
import userAddComponent from './components/admin/users/add';
import userEditComponent from './components/admin/users/edit';
import userProfileComponent from './components/admin/users/profile'
Vue.component('userlist-component', userListComponent);
Vue.component('useradd-component', userAddComponent);
Vue.component('useredit-component', userEditComponent);
Vue.component('userprofile-component', userProfileComponent);

//sales
import salesListComponent from './components/admin/sales/list';
Vue.component('sales-list-component', salesListComponent);
import salesAddComponent from './components/admin/sales/add';
Vue.component('sales-add-component', salesAddComponent);
import salesEditComponent from './components/admin/sales/edit';
Vue.component('sales-edit-component', salesEditComponent);

//clients
import clientsListComponent from './components/admin/clients/list';
Vue.component('clients-list-component', clientsListComponent);
import clientsAddComponent from './components/admin/clients/add';
Vue.component('clients-add-component', clientsAddComponent);
import clientsEditComponent from './components/admin/clients/edit';
Vue.component('clients-edit-component', clientsEditComponent);

//sellers
import sellersListComponent from './components/admin/sellers/list';
Vue.component('sellers-list-component', sellersListComponent);
import sellersAddComponent from './components/admin/sellers/add';
Vue.component('sellers-add-component', sellersAddComponent);
import sellersEditComponent from './components/admin/sellers/edit';
Vue.component('sellers-edit-component', sellersEditComponent);

//productattachments
import productattachmentsListComponent from './components/admin/productattachments/list';
Vue.component('productattachments-list-component', productattachmentsListComponent);

//products
import productsListComponent from './components/admin/products/list';
Vue.component('products-list-component', productsListComponent);
import productsAddComponent from './components/admin/products/add';
Vue.component('products-add-component', productsAddComponent);
import productsEditComponent from './components/admin/products/edit';
Vue.component('products-edit-component', productsEditComponent);

//configurations
import configurationsListComponent from './components/admin/configurations/list';
Vue.component('configurations-list-component', configurationsListComponent);

//comissions
import comissionsListComponent from './components/admin/comissions/list';
Vue.component('comissions-list-component', comissionsListComponent);

//maintenance
import maintenanceComponent from './components/admin/maintenance';
Vue.component('maintenance-component', maintenanceComponent);

//notifications
import NotificationListComponent from './components/admin/notifications/list';
Vue.component('notifications-list-component', NotificationListComponent);

//contents
import contentsListComponent from './components/admin/contents/list';
Vue.component('contents-list-component', contentsListComponent);
import contentsAddComponent from './components/admin/contents/add';
Vue.component('contents-add-component', contentsAddComponent);
import contentsEditComponent from './components/admin/contents/edit';
Vue.component('contents-edit-component', contentsEditComponent);

//balances
import BalancesListComponent from './components/admin/balances/list';
Vue.component('balances-list-component', BalancesListComponent);

//partners
import partnersListComponent from './components/admin/partners/list';
Vue.component('partners-list-component', partnersListComponent);
import partnersAddComponent from './components/admin/partners/add';
Vue.component('partners-add-component', partnersAddComponent);
import partnersEditComponent from './components/admin/partners/edit';
Vue.component('partners-edit-component', partnersEditComponent);

//integrations
import IntagrationsComponent from './components/admin/integrations/list';
Vue.component('admin-integrations-component', IntagrationsComponent);


//RENDER
const app = new Vue({
	el: '#app',
	methods: {
		MakeUrl(path) {
			return BaseUrl(path);
		}
	}
});
