<template>
	<div>
    <div class="card">
      <div class="card-body">

        <div class="text-center p-3 text-muted">
          <h5>MANUTENÇÃO</h5>
        </div>

      </div>
    </div>		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		mounted() {
			
		},
		methods: {
			
		}
	}
</script>
