<template>
	<div>
		<div class="row">
			<div class="col-12">

				<div class="card">

					<!-- card header -->
					<div class="card-header">
						<h4>Preencha os dados abaixo</h4>

						<a @click="newData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
					</div>
					<!-- end card header -->

					<!-- card body -->
					<div class="card-body">

						<!-- form -->
						
						<div class="">
							<div class="row">

								<!-- title -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-title">Título</label>
										<input type="text" id="input-title" v-bind:class="{'is-invalid': errors.title}"   class="form-control" placeholder="Título" v-model="title" required="false" >
										<div class="invalid-feedback" v-if="errors.title">
											<p>{{ errors.title[0] }}</p>
										</div>
									</div>
								</div>

								<!-- status -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-status">Status</label>
										<v-select :options="statusOptions" label="label" v-bind:class="{'is-invalid': errors.status}" :reduce="label => label.id" v-model="status"></v-select>
										<div class="invalid-feedback" v-if="errors.status">
											<p>{{ errors.status[0] }}</p>
										</div>
									</div>
								</div>

								<!-- visible to -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-status">Visível para</label>
										<v-select :options="visibleOptions" label="label" v-bind:class="{'is-invalid': errors.visible_to}" :reduce="label => label.id" v-model="visible_to"></v-select>
										<div class="invalid-feedback" v-if="errors.visible_to">
											<p>{{ errors.visible_to[0] }}</p>
										</div>
									</div>
								</div>

								<!-- emphasis -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-emphasis">Destaque</label>
										<v-select :options="emphasisOptions" label="label" v-bind:class="{'is-invalid': errors.emphasis}" :reduce="label => label.id" v-model="emphasis"></v-select>
										<div class="invalid-feedback" v-if="errors.emphasis">
											<p>{{ errors.emphasis[0] }}</p>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">

			<!-- infos -->
			<div class="col-md-8">
				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Dados</h6>

						<div class="row">

							<!-- stock_available -->
							<!-- <div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-stock_available">Estoque Disponível</label>
									<input type="number" id="input-stock_available" v-bind:class="{'is-invalid': errors.stock_available}"   class="form-control" placeholder="Estoque Disponível" v-model="stock_available" required="true" >
									<div class="invalid-feedback" v-if="errors.stock_available">
										<p>{{ errors.stock_available[0] }}</p>
									</div>
								</div>
							</div> -->

							<!-- stock_minimum -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-stock_minimum">Estoque Mínimo</label>
									<input type="number" id="input-stock_minimum" v-bind:class="{'is-invalid': errors.stock_minimum}"   class="form-control" placeholder="Estoque Mínimo" v-model="stock_minimum" required="false" >
									<div class="invalid-feedback" v-if="errors.stock_minimum">
										<p>{{ errors.stock_minimum[0] }}</p>
									</div>
								</div>
							</div>

							<!-- batch -->
							<div class="col-md-8">
								<div class="form-group">
									<label class="form-control-label" for="input-batch">Lote</label>
									<input type="text" id="input-batch" v-bind:class="{'is-invalid': errors.batch}"   class="form-control" placeholder="Lote" v-model="batch">
									<div class="invalid-feedback" v-if="errors.batch">
										<p>{{ errors.batch[0] }}</p>
									</div>
								</div>
							</div>

							<!-- value -->
							<!-- <div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-value">Preço</label>
									<div class="input-group mb-3">
										<div class="input-group-prepend">
											<span class="input-group-text">R$</span>
										</div>
										<input type="number" id="input-value" v-bind:class="{'is-invalid': errors.value}"   class="form-control" placeholder="Preço" v-model="value" required="true" >
									</div>
									<div class="invalid-feedback" v-if="errors.value">
										<p>{{ errors.value[0] }}</p>
									</div>
								</div>
							</div> -->

							<!-- validity -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-validity">Validade</label>
									<input type="date" id="input-validity" v-bind:class="{'is-invalid': errors.validity}"   class="form-control" placeholder="Validade" v-model="validity">
									<div class="invalid-feedback" v-if="errors.validity">
										<p>{{ errors.validity[0] }}</p>
									</div>
								</div>
							</div>

							<!-- recurrence -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-recurrence">Recorrência</label>
									<div class="input-group mb-3">
										<input type="number" id="input-recurrence" v-bind:class="{'is-invalid': errors.recurrence}"   class="form-control" placeholder="Recorrência" v-model="recurrence" required="false" >
										<div class="input-group-prepend">
											<span class="input-group-text">dias</span>
										</div>
									</div>
									
									<div class="invalid-feedback" v-if="errors.recurrence">
										<p>{{ errors.recurrence[0] }}</p>
									</div>
								</div>
							</div>

							<!-- link -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-link">Link</label>
									<input type="text" id="input-link" v-bind:class="{'is-invalid': errors.link}"   class="form-control" placeholder="Link" v-model="link" required="true" >
									<div class="invalid-feedback" v-if="errors.link">
										<p>{{ errors.link[0] }}</p>
									</div>
								</div>
							</div>

							<!-- description -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-description">Descrição</label>
									<textarea type="text" id="input-description" v-bind:class="{'is-invalid': errors.description}" class="form-control" placeholder="Descrição" v-model="description" rows="8" required="false"></textarea>
									<div class="invalid-feedback" v-if="errors.description">
										<p>{{ errors.description[0] }}</p>
									</div>
								</div>
							</div>

						</div>

					</div>
				</div>

				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Informações Frete</h6>

						<div class="row">

							<!-- delivery_height -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-delivery_height">Altura</label>
									<div class="input-group mb-3">
										<!-- <input type="number" id="input-delivery_height" v-bind:class="{'is-invalid': errors.delivery_height}"   class="form-control" placeholder="Altura" v-model="delivery_height" required="false" > -->
										<money v-model="delivery_height" v-bind="decimal" class="form-control"></money>
										<div class="input-group-prepend">
											<span class="input-group-text">cm</span>
										</div>
									</div>
									<div class="invalid-feedback" v-if="errors.delivery_height">
										<p>{{ errors.delivery_height[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end delivery_height -->

							<!-- delivery_length -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-delivery_length">Comprimento</label>
									<div class="input-group mb-3">
										<!-- <input type="number" id="input-delivery_length" v-bind:class="{'is-invalid': errors.delivery_length}"   class="form-control" placeholder="Comprimento" v-model="delivery_length" required="false" > -->
										<money v-model="delivery_length" v-bind="decimal" class="form-control"></money>
										<div class="input-group-prepend">
											<span class="input-group-text">cm</span>
										</div>
									</div>
									<div class="invalid-feedback" v-if="errors.delivery_length">
										<p>{{ errors.delivery_length[0] }}</p>
									</div>
								</div>
							</div>

							<!-- delivery_depth -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-delivery_depth">Largura</label>
									<div class="input-group mb-3">
										<!-- <input type="number" id="input-delivery_depth" v-bind:class="{'is-invalid': errors.delivery_depth}"   class="form-control" placeholder="Largura" v-model="delivery_depth" required="false" > -->
										<money v-model="delivery_depth" v-bind="decimal" class="form-control"></money>
										<div class="input-group-prepend">
											<span class="input-group-text">cm</span>
										</div>
									</div>
									<div class="invalid-feedback" v-if="errors.delivery_depth">
										<p>{{ errors.delivery_depth[0] }}</p>
									</div>
								</div>
							</div>

							<!-- delivery_weight -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-delivery_weight">Peso</label>
									<div class="input-group mb-3">
										<!-- <input type="text" id="input-delivery_weight" v-bind:class="{'is-invalid': errors.delivery_weight}"   class="form-control" placeholder="Peso" v-model="delivery_weight" required="false" > -->
										<money v-model="delivery_weight" v-bind="decimal" class="form-control"></money>
										<div class="input-group-prepend">
											<span class="input-group-text">kg</span>
										</div>
									</div>
									<div class="invalid-feedback" v-if="errors.delivery_weight">
										<p>{{ errors.delivery_weight[0] }}</p>
									</div>
								</div>
							</div>

						</div>

					</div>
				</div>

				<!-- additional -->
				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Informações Adicionais</h6>

						<div class="row">

							<!-- use -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-use">Uso</label>
									<textarea type="text" id="input-use" v-bind:class="{'is-invalid': errors.use}" class="form-control" placeholder="Uso (Aplicações)" v-model="use" rows="8" required="false"></textarea>
									<div class="invalid-feedback" v-if="errors.use">
										<p>{{ errors.use[0] }}</p>
									</div>
								</div>
							</div>

							<!-- modo of use -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-mode_of_use">Modo de Usar</label>
									<textarea type="text" id="input-mode_of_use" v-bind:class="{'is-invalid': errors.mode_of_use}" class="form-control" placeholder="Modo de Usar" v-model="mode_of_use" rows="8" required="false"></textarea>
									<div class="invalid-feedback" v-if="errors.mode_of_use">
										<p>{{ errors.mode_of_use[0] }}</p>
									</div>
								</div>
							</div>

							<!-- composition -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-composition">Composição</label>
									<textarea type="text" id="input-composition" v-bind:class="{'is-invalid': errors.composition}" class="form-control" placeholder="Composição" v-model="composition" rows="8" required="false"></textarea>
									<div class="invalid-feedback" v-if="errors.composition">
										<p>{{ errors.composition[0] }}</p>
									</div>
								</div>
							</div>

							<!-- precautions -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-precautions">Precauções</label>
									<textarea type="text" id="input-precautions" v-bind:class="{'is-invalid': errors.precautions}" class="form-control" placeholder="Precauções" v-model="precautions" rows="8" required="false"></textarea>
									<div class="invalid-feedback" v-if="errors.precautions">
										<p>{{ errors.precautions[0] }}</p>
									</div>
								</div>
							</div>

						</div>

					</div>
				</div>

			</div>

			<!-- price and pictures -->
			<div class="col-md-4">

				<!-- price -->
				<div class="card">
					<div class="card-body">
						<h6 class="heading-small text-muted mb-4">Preço</h6>

						<!-- form -->
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-amount_start">Qtd Inicial</label>
									<input type="number" id="input-amount_start" class="form-control" placeholder="0" v-model="priceInfo.amount_start" required="true" >
								</div>
							</div>

							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-amount_end">Qtd Final</label>
									<input type="number" id="input-amount_end" class="form-control" placeholder="0" v-model="priceInfo.amount_end" required="true" >
								</div>
							</div>

							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-value">Valor Unitário</label>
									<div class="input-group mb-3">
										<div class="input-group-prepend">
											<span class="input-group-text">R$</span>
										</div>
										<!-- <input type="number" id="input-value" class="form-control" placeholder="Preço" v-model="priceInfo.value" required="true" > -->
										<money v-model="priceInfo.value" v-bind="money" class="form-control"></money>
									</div>
								</div>
							</div>

							<div class="col-md-12 text-right">
								<a href="#" class="btn btn-outline-success" @click="saveProductPrice()">SALVAR</a>
							</div>
						</div>

						<div class="row">
							<div class="col-md-12">
								<hr/>

								<!-- list -->
								<div class="table-responsive" v-if="productPrices.length > 0">
									<table class="table">
										<thead>
											<tr>
												<th scope="col">Quantidade</th>
												<th scope="col">Valor</th>
												<th scope="col">#</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="itemPrice, itemIndex in productPrices" :key="itemIndex">
												<td>{{ itemPrice.amount_start }} até {{ itemPrice.amount_end }}</td>
												<td>R$ {{ parseFloat(itemPrice.value).toFixed(2) }}</td>
												<td>
													<button class="btn btn-sm btn-outline-danger" @click="removePrice(itemIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-else class="text-center p-3 text-muted">
									<h5 style="font-size:14px;">Nenhum preço cadastrado!</h5>
								</div>

							</div>
						</div>
					</div>
				</div>

				<!-- pictures -->
				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Fotos</h6>

						<!-- <a class="btn btn-outline-primary w-100" href="" @click="selectPicture()">Nova Foto</a> -->

						<div class="row">
							<div class="col-md-12">
								<label class="form-control-label" for="input-picture">Clique para selecionar a imagem</label>
								<div class="custom-file">
									<input type="file" class="custom-file-input" id="picture" name="picture" accept="image/*" ref="picture" lang="pt" v-on:change="handleFilePictureUpload()">
									<label class="custom-file-label" for="logotipo">{{filePictureName}}</label>
								</div>

							</div>
						</div>

						<div class="row list-images">
							<div class="col-md-12 item-image" v-for="item, index in pictures" :key="index">
								<img :src="item.show"/>
								<span class="image-delete" @click="removeImage(index)">
									<i class="fas fa-trash-alt"></i>
								</span>
							</div>
						</div>

					</div>
				</div>
			</div>

		</div>

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>

	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		data() {
			return {
				batch: "",
				title: "",
				description: "",
				link: "",
				recurrence: "",
				delivery_value: "",
				delivery_percent: "",
				delivery_height: "",
				delivery_length: "",
				delivery_depth: "",
				delivery_weight: "",
				emphasis: 0,
				emphasisOptions: [{
					id: 0,
					label: "Não"
				},{
					id: 1,
					label: "Sim"
				}],
				priceInfo: {},
				productPrices: [],
				stock_available: "",
				stock_minimum: "",
				status: 1,
				value: '',
				errors: [],
				filePictureName: 'Nenhuma foto selecionada',
				message: "",
				loading: false,
				fullPage: true,
				pictures: [],
				use: "",
				mode_of_use: "",
				precautions: "",
				composition: "",
				decimal: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 3,
          masked: false
        },
				money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false
        },
				statusOptions:[{
					id: 0,
					label: 'Inativo'
				},{
					id: 1,
					label: 'Ativo'
				}],
				validity: "",
				visibleOptions:[{
					id: 'client',
					label: 'Cliente'
				},{
					id: 'partner',
					label: 'Parceiro'
				},{
					id: 'seller',
					label: 'Vendedor'
				},{
					id: 'both',
					label: 'Todos'
				}],
				visible_to: 'both'
			}
		},
		mounted() {
		},
		methods: {
			handleFilePictureUpload(){
				this.pictures.push({
					file: this.$refs.picture.files[0],
					show: URL.createObjectURL(this.$refs.picture.files[0])
				});
			},
			newData() {
				let _this = this;

				//validate prices
				if(_this.productPrices.length > 0){

					_this.errors = [];
					_this.message = "";
					_this.loading = true;

					let dataInfo = {
						"title" : _this.title,
						"description" : _this.description,
						"link" : _this.link,
						"recurrence" : _this.recurrence,
						"value" : _this.value,
						"batch": _this.batch,
						"visible_to": _this.visible_to,
						"validity": _this.validity,
						"delivery_value" : _this.delivery_value,
						"delivery_percent" : _this.delivery_percent,
						"delivery_height" : _this.delivery_height,
						"delivery_length" : _this.delivery_length,
						"delivery_depth" : _this.delivery_depth,
						"delivery_weight" : _this.delivery_weight,
						"emphasis" : _this.emphasis,
						"stock_available" : _this.stock_available,
						"stock_minimum" : _this.stock_minimum,
						"status" : _this.status,
						"use" : _this.use,
						"mode_of_use" : _this.mode_of_use,
						"composition" : _this.composition,
						"precautions" : _this.precautions,
						"prices": JSON.stringify(_this.productPrices)
					};

					axios.post("/admin/products", dataInfo).then((res) => {

						var countPictures = 1;
						var productID = res.data.data.id;

						if(this.pictures.length > 0){

							this.pictures.map((rowPicture) => {

								let formData = new FormData();
								formData.append("file", rowPicture.file);
								formData.append("product", productID);

								axios.post("/admin/productattachments", formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

									if(countPictures == this.pictures.length){

										_this.loading = false;
										_this.resetForm();

										//call function in ApiService to validate success
										_this._api_validateSuccess(res,true);

									} else {

										countPictures++;

									}

								}).catch((err) => {

									_this.loading = false;

									if (err.response.data.errors){
										//set errors
										_this.errors = err.response.data.errors;
									}

									//call function in ApiService to validate error
									_this._api_validateError(err);

								});

							});

						} else {

							_this.loading = false;
							_this.resetForm();

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			removePrice(index){
				this.productPrices.splice(index,1);
			},
			resetForm() {
				this.title = '';
				this.description = '';
				this.link = '';
				this.recurrence = '';
				this.delivery_value = '';
				this.delivery_percent = '';
				this.delivery_height = '';
				this.delivery_length = '';
				this.delivery_depth = '';
				this.delivery_weight = '';
				this.emphasis = 0;
				this.stock_available = '';
				this.stock_minimum = '';
				this.status = '';
				this.productPrices = [];
				this.priceInfo = {};

				this.errors = [];

			},
			removeImage(index){
				this.pictures.splice(index,1);
			},
			saveProductPrice(){
				if(this.priceInfo.amount_start && this.priceInfo.amount_end && this.priceInfo.value){
					this.productPrices.push(this.priceInfo);
					this.priceInfo = {};
				}
			},
			selectPicture(){
				$("#picture").click();
			}
		}
	}
</script>
