<template>
  <section class="integrations">

    <div class="row">
      <div class="col-md-12">

        <!-- list all integrations -->

         <!-- PagarMe -->
         <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-1 status" :class="pagarme ? 'bg-success' : 'bg-danger'">
                <i class="fas fa-check" v-if="pagarme"></i>
                <i class="fas fa-exclamation" v-else></i>
              </div>
              <div class="col-md-10">
                <span><img src="/images/pagarme.png" style="height: 30px;margin: 0 15px 0 0;" /></span>
              </div>
              <div class="col-md-1 button">
                <a href="#"><i class="fas fa-minus"></i></a>
              </div>
              
            </div>
          </div>
        </div>

        <!-- Melhor Envio -->
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-1 status" :class="melhor_envio ? 'bg-success' : 'bg-danger'">
                <i class="fas fa-check" v-if="melhor_envio"></i>
                <i class="fas fa-exclamation" v-else></i>
              </div>
              <div class="col-md-10">
                <span><img src="/images/melhorenvio.png" style="height: 30px;margin: 0 15px 0 0;" /></span>
              </div>
              <div class="col-md-1 button">
                <a href="#" @click="updateMelhorEnvio()">
                  <i class="fas fa-sync-alt"></i>
                </a>
              </div>
              
            </div>
          </div>
        </div>

        <!-- Correios -->
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-1 status" :class="correios ? 'bg-success' : 'bg-danger'">
                <i class="fas fa-check" v-if="correios"></i>
                <i class="fas fa-exclamation" v-else></i>
              </div>
              <div class="col-md-10">
                <span><img src="/images/correios.png" style="height: 30px;margin: 0 15px 0 0;" /></span>
              </div>
              <div class="col-md-1 button">
                <a href="#" @click="updateCorreios()">
                  <i class="fas fa-sync-alt"></i>
                </a>
              </div>
              
            </div>
          </div>
        </div>

      </div>
    </div>

    <vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>

  </section>
</template>
<script>
  export default {
    data() {
			return {
        configurationsItems: [],
        configurationID: null,
        correios: false,
        melhor_envio: false,
        pagarme: true,
        fullPage: true,
				errors: [],
				loading: false
      }
    },
    mounted() {
      this.searchConfigurations();
      //this.verifyTokenMelhorEnvio();
    },
    methods: {
      searchConfigurations() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/configurations/search/system").then((res) => {
					_this.configurationsItems = _this.configurationsItems.concat(res.data);
          
          //read all to validate delivery plataform configurated
          res.data.map((rowConfiguration) => {
            if(rowConfiguration.name == "Plataforma Entrega"){
              _this.configurationID = rowConfiguration.id;
              if(rowConfiguration.content == "Melhor Envio"){
                _this.melhor_envio = true;
                _this.correios = false;
                this.verifyTokenMelhorEnvio();
              } else {
                _this.melhor_envio = false;
                _this.correios = true;
              }
            }
          });

					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
      updateCorreios(){
        let _this = this;

        //validate is activated
        if(_this.correios){

          //is activated
          //show alert confirmation
          _this.$swal({
            title: "Desativar Correios",
            text: "Ao desativar os Correios a plataforma Melhor Envio será ativada. Tem certeza que deseja desativar?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Desativar",
            cancelButtonText: "Cancelar"
          }).then((alertConfirm) => {

            //validate isConfirmed
            if (alertConfirm.isConfirmed) {

              let dataInfo = {
                "name" : "Plataforma Entrega",
						    "type" : "system",
                "content" : "Melhor Envio",
                "_method": "PATCH"
              };
              axios.post("/admin/configurations/"+_this.configurationID, dataInfo).then((res) => {

                location.reload();

              }).catch((err) => {

                location.reload();

              });

            }

          });

        } else {

          //is deactivated
          //show alert confirmation
          _this.$swal({
            title: "Ativar Correios",
            text: "Ao ativar os Correios a plataforma Melhor Envio será desativada. Tem certeza que deseja ativar?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ativar",
            cancelButtonText: "Cancelar"
          }).then((alertConfirm) => {

            //validate isConfirmed
            if (alertConfirm.isConfirmed) {

              let dataInfo = {
                "name" : "Plataforma Entrega",
						    "type" : "system",
                "content" : "Correios",
                "_method": "PATCH"
              };
              axios.post("/admin/configurations/"+_this.configurationID, dataInfo).then((res) => {

                location.reload();

              }).catch((err) => {

                location.reload();

              });

            }

          });

        }
      },
      updateMelhorEnvio(){
        if(this.melhor_envio){
          axios.get("/admin/melhorenvio/authorize").then((res) => {
            location.href = res.data.url;
            _this.loading = false;
          }).catch((err) => {
            _this.loading = false;
          });
        }
      },
      verifyTokenMelhorEnvio(){
				let _this = this;
				_this.loading = true;
				axios.get("/admin/melhorenvio/token").then((res) => {
					this.melhor_envio = res.data.status;
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			}
    }};
</script>