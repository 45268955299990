<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos produtos cadastrados</p>
				<a href="/admin/products/create" class="btn btn-outline-success float-right"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="productsItems.length"
						:columns="tableColumns"
						:rows="productsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_image'">
								<div v-if="props.row.attachments.length > 0">
									<img :src="'/storage/products/'+props.row.attachments[0].file" style="width: 80px; height: 80px; object-fit: contain;" />
								</div>
							</span>
							<span v-if="props.column.field == '_status'">
								<div v-if="props.row.status == 0">Inativo</div>
								<div v-if="props.row.status == 1">Ativo</div>
							</span>
							<span v-if="props.column.field == '_value'">
								<div v-if="props.row.prices.length > 0">
									R$ {{ _formatPrice(props.row.prices[0].value) }}
								</div>
								<div v-else>
									-
								</div>
							</span>
							<span v-if="props.column.field == '_stock'">
								{{ validateStock(props.row) }}
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-primary" @click="viewData(props.row)" title="Ver Página de Vendas"><i class="fas fa-shopping-cart"></i></button>
									<button class="btn btn-sm btn-outline-warning" @click="manageStock(props.row)" title="Gerenciar Estoque"><i class="fas fa-th"></i></button>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum produto cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalManageStock" tabindex="-1" aria-labelledby="modalManageStockLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered modal-lg">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalManageStockLabel">Gerenciar Estoque</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="updateProductStock">

								<div class="row">

                  <!-- type -->
									<div class="col-md-2">
                    <div class="form-group">
                      <label class="form-control-label" for="input-sellers">Tipo</label>
                      <v-select :options="typeOptions" label="label" :reduce="label => label.id" v-model="modalStock.type" required="true" ></v-select>
                    </div>
                  </div>

                  <!-- amount -->
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-control-label" for="input-amount">Quantidade</label>
                      <input type="number" id="input-amount" class="form-control" placeholder="1" v-model="modalStock.amount" required="true" >
                    </div>
                  </div>

									<!-- observations -->
                  <div class="col-md-7">
                    <div class="form-group">
                      <label class="form-control-label" for="input-observations">Observações</label>
                      <input type="text" id="input-observations" class="form-control" placeholder="Digite aqui as observações...." v-model="modalStock.observations" >
                    </div>
                  </div>

									<div class="col-md-1">
										<button type="submit" class="btn btn-success" style="margin-top:27px;height: 42px;">
											<i class="fas fa-plus"></i>
										</button>
									</div>

                </div>

								<hr/>

								<div class="row">
									<div class="col">

										<!-- table -->
										<vue-good-table v-if="modalStock.history.length"
											:columns="tableStockColumns"
											:rows="modalStock.history"
											:pagination-options="tablePagination"
											:search-options="{
												enabled: true,
												placeholder: 'Buscar..',
											}"
											styleClass="vgt-table"
										>
											<template slot="table-row" slot-scope="props">
												<span v-if="props.column.field == '_type'">
													<div class="text-success" v-if="props.row.type == 0">Entrada</div>
													<div class="text-danger" v-if="props.row.type == 1">Saída</div>
												</span>
												<span v-else>
													{{ props.formattedRow[props.column.field] }}
												</span>
											</template>
										</vue-good-table>
										<div v-else class="text-center p-3 text-muted">
											<h5>Nenhuma movimentação de estoque encontrada!</h5>
										</div>

										<div class="text-center p-4 text-muted" v-if="loading">
											<h5>Carregando...</h5>
											<p>Aguarde até que os dados sejam carregados...</p>
										</div>

									</div>
								</div>

							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import utilsService from '../../utils.js';
	export default {
		mixins: [ApiService, utilsService],
		props: ['permissions'],
		data() {
			return {
				productsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				modalStock: {
					product: '',
					type: 0,
					amount: '',
					observations: '',
					history: []
				},
				typeOptions: [{
          id: 0,
          label: "Entrada"
        },{
          id: 1,
          label: "Saída"
        }],
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
				{
						label: '',
						field: '_image',
					},{
						label: 'Título',
						field: 'title',
					},{
						label: 'Valor',
						field: '_value',
					},{
						label: 'Estoque Disponível',
						field: '_stock',
					},{
						label: 'Status',
						field: '_status',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '160px'
					}
				],
				tableStockColumns: [
					{
						label: 'Tipo',
						field: '_type',
					},{
						label: 'Quantidade',
						field: 'amount',
					},{
						label: 'Observações',
						field: 'observations',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.productsItems = [];
				_this.loading = true;
				axios.get("/admin/products").then((res) => {
					_this.productsItems = _this.productsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Produto",
					text: "Tem certeza que deseja excluir esse Produto?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/products/"+ID).then((res) => {

							_this.products.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){
				location.href="/admin/products/"+item.id+"/edit";
			},
			manageStock(product){
				this.modalStock = {
					product: product.id,
					type: 0,
					amount: '',
					observations: '',
					history: product.stock
				};
				$("#modalManageStock").modal('show');
			},
			resetForm(){

				this.product = {
					title: '',
					description: '',
					link: '',
					recurrence: '',
					delivery_value: '',
					delivery_percent: '',
					delivery_height: '',
					delivery_length: '',
					delivery_depth: '',
					delivery_weight: '',
					stock_available: '',
					stock_minimum: '',
					status: '',
				};

				this.errors = [];

			},
			updateProductStock(){
				let _this = this;

				//validate prices
				if(_this.modalStock.amount != ''){

					_this.errors = [];
					_this.message = "";
					_this.loading = true;

					let dataInfo = {
						"product" : _this.modalStock.product,
						"type" : _this.modalStock.type,
						"amount" : _this.modalStock.amount,
						"observations" : _this.modalStock.observations
					};

					axios.post("/admin/products/"+_this.modalStock.product+"/managestock", dataInfo).then((res) => {

						_this.loading = false;
						_this.modalStock.amount = '';
						_this.modalStock.observations = '';

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

						_this.modalStock.history = [];
						_this.modalStock.history = res.data.data;
						_this.loadItems();
								
					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			validateStock(product){
				if(product.stock.length == 0){
					return "0";
				} else {

					var totalStock = 0;
					product.stock.map((rowStock) => {
						if(rowStock.type == 0){
							totalStock = totalStock + rowStock.amount;
						} else {
							totalStock = totalStock - rowStock.amount;
						}
					});
					return totalStock;

				}
			},
			viewData(item){
				window.open("/sale/"+item.slug,"_blank");
			}
		}
	}
</script>
