<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todas vendas cadastradas</p>
				<a href="/admin/sales/create" class="btn btn-outline-success float-right"><i class="fas fa-plus"></i>&nbsp;Nova Venda</a>
				<a class="btn btn-outline-warning float-right mr-4" data-toggle="collapse" href="#panelFilter" role="button" aria-expanded="false" aria-controls="panelFilter"><i class="fas fa-filter"></i>&nbsp;Filtrar</a>
			</div>
		</div>

		<!-- filter -->
		<div class="row">
			<div class="col-md-12">
				<div class="collapse" id="panelFilter">
					<div class="card card-body">
						<div class="row">

							<!-- product_id -->
							<div class="col-md-6 mt-2">
								<label class="form-control-label" for="input-product_id">Produto</label>
								<v-select :options="productsOptions" label="title" :reduce="title => title.id" v-model="filterItems.product_id"></v-select>
							</div>
							
							<!-- client_id -->
							<div class="col-md-6 mt-2">
								<label class="form-control-label" for="input-client_id">Cliente</label>
								<v-select :options="clientsOptions" label="name" :reduce="name => name.id" v-model="filterItems.client_id"></v-select>
							</div>

							<!-- seller_id -->
							<div class="col-md-6 mt-2">
								<label class="form-control-label" for="input-seller_id">Vendedor</label>
								<v-select :options="sellersOptions" label="name" :reduce="name => name.id" v-model="filterItems.seller_id"></v-select>
							</div>

							<div class="col-md-3 mt-2">
								<label class="form-control-label" for="input-start_date">Data Inicial</label>
								<input type="date" id="input-start_date"   class="form-control" placeholder="Data prevista" v-model="filterItems.start_date" required="false" >
							</div>

							<div class="col-md-3 mt-2">
								<label class="form-control-label" for="input-end_date">Data Final</label>
								<input type="date" id="input-end_date"   class="form-control" placeholder="Data prevista" v-model="filterItems.end_date" required="false" >
							</div>

							<!-- status -->
							<div class="col-md-6 mt-2">
								<label class="form-control-label" for="input-status">Status</label>
								<v-select :options="statusOptions" label="title" v-model="filterItems.status"></v-select>
							</div>

							<!-- status commission -->
							<div class="col-md-6 mt-2">
								<label class="form-control-label" for="input-status">Status da Comissão</label>
								<v-select :options="statusCommissionOptions" label="title" v-model="filterItems.status_commission"></v-select>
							</div>
							
							<div class="col-md-12 text-right mt-2">
								<button class="btn btn-success" @click="filter()">FILTRAR</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- widgets -->
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-primary">
            <i class="fas fa-chart-line"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header text-left">
              <h4>Vendas Diretas</h4>
            </div>
            <div class="card-body">
              {{ widgetInfo.sales_direct }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-success">
            <i class="fas fa-dollar-sign"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Vendas Direta (R$)</h4>
            </div>
            <div class="card-body">
              R$ {{ _formatPrice(widgetInfo.sales_direct_amount) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-warning">
            <i class="fas fa-dollar-sign"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Total Comissões Pendentes</h4>
            </div>
            <div class="card-body">
              R$ {{ _formatPrice(widgetInfo.total_comissions_pending) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
			<div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-primary">
            <i class="fas fa-chart-line"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Vendas Indicação</h4>
            </div>
            <div class="card-body">
              {{ widgetInfo.sales_indication }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-success">
            <i class="fas fa-dollar-sign"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Vendas Indicação (R$)</h4>
            </div>
            <div class="card-body">
              R$ {{ _formatPrice(widgetInfo.sales_indication_amount) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-success">
            <i class="fas fa-dollar-sign"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Total Comissões Pagas</h4>
            </div>
            <div class="card-body">
              R$ {{ _formatPrice(widgetInfo.sales_indication_amount) }}
            </div>
          </div>
        </div>
      </div>
    </div>

		<!-- list -->
		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="salesItems.length"
						:columns="tableColumns"
						:rows="salesItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_status'">
								<div class="text-success" v-if="props.row.status[0].status === 'Concluída' || props.row.status[0].status === 'Pagamento Confirmado'">{{props.row.status[0].status}}</div>
								<div class="text-warning" v-else>{{props.row.status[0].status}}</div>
							</span>
							<span v-if="props.column.field == '_type'">
								<div v-if="props.row.seller_id == null || props.row.seller_id == null">
									Cliente
								</div>
								<div v-else>
									Vendedor
								</div>
							</span>
							<span v-if="props.column.field == '_name'">
								<div v-if="props.row.seller_id == null || props.row.seller_id == 0">
									<div v-if="props.row.clients">
										{{props.row.clients.name}}
									</div>
								</div>
								<div v-else>
									<div v-if="props.row.sellers">
										{{props.row.sellers.name}}
									</div>
								</div>
							</span>
							<span v-if="props.column.field == '_total'">
								R$ {{ _formatPrice(props.row.total) }}
							</span>
							<span v-if="props.column.field == '_delivery'">
								<div v-if="props.row.delivery_type == 'pac'">PAC</div>
								<div v-if="props.row.delivery_type == 'sedex'">Sedex</div>
								<div v-if="props.row.delivery_type == 'sedex_10'">Sedex 10</div>
								<div v-if="props.row.delivery_type == 'sedex_12'">Sedex 12</div>
								<div v-if="props.row.delivery_type == 'sedex_today'">Sedex Hoje</div>
								<div v-if="props.row.delivery_type == 'to_remove'">Retirar</div>
								<div v-if="props.row.delivery_type == 'delivery'">Entregar</div>
							</span>
							<span v-if="props.column.field == '_payment'">
								<div v-if="props.row.payment_type == 'pix'">Pix - Manual</div>
								<div v-if="props.row.payment_type == 'card'">Cartão - Manual</div>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div class="dropdown">
									<button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
										<i class="fas fa-cogs"></i>
									</button>
									<div class="dropdown-menu">
										<a class="dropdown-item" href="#" v-if="props.row.status_comission == 0" @click="validateStatusCommission(props.row)" title="Atualizar Status da Comissão">Atualizar Status Comissão</a>
										<a class="dropdown-item" href="#" v-if="userCan.edit" @click="editData(props.row)" title="Editar">Editar</a>
										<a class="dropdown-item" href="#" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar">Deletar</a>
									</div>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhuma venda cadastrada!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			 <!-- modal comission -->
			 <div class="modal fade" id="modalUpdateCommission" tabindex="-1" aria-labelledby="modalUpdateCommissionLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalUpdateCommissionLabel">Atualizar Comissão</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form class="pad0" v-on:submit.prevent="saveCommission">

								<div class="row">

									<div class="col-md-12">
										<div class="form-group">
											<label>Vendedor:</label>
											<input class="form-control" v-model="modalComission.seller" :disabled="true"/>
										</div>
									</div>

									<div class="col-md-12">
										<div class="form-group">
											<label>Valor da Compra:</label>
											<input class="form-control" v-model="modalComission.total_sale" :disabled="true"/>
										</div>
									</div>

									<div class="col-md-12">
										<div class="form-group">
											<label>Percentual de Comissão:</label>
											<input class="form-control" v-model="modalComission.commission" :disabled="true"/>
										</div>
									</div>

									<div class="col-md-12">
										<div class="form-group">
											<label>Valor da Comissão:</label>
											<input class="form-control" v-model="modalComission.total_commission" :disabled="true"/>
										</div>
									</div>

									<div class="col-md-12">
										<div class="text-right" style="margin-top: 30px;">
											<button type="submit" class="btn btn-success">COMISSÃO PAGA</button>
										</div>
									</div>

								</div>

							</form>
						</div>
					</div>
				</div>
			</div>

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import utilsService from '../../utils.js';
	export default {
		mixins: [ApiService, utilsService],
		props: ['permissions'],
		data() {
			return {
				sellersOptions: [],
				clientsOptions: [],
				productsOptions: [],
				salesItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				modalComission: {
					seller: '',
					total_sale: 'R$ 0.00',
					commission: '5%',
					total_commission: 'R$ 0.00'
				},
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: '',
						field: 'actions',
						sortable: false,
						width: '30px'
					// },{
					// 	label: 'Produto',
					// 	field: 'products.title',
					// },{
					// 	label: 'Qtd',
					// 	field: 'amount',
					},{
						label: 'Status',
						field: '_status',
					},{
						label: 'Comprado Por',
						field: '_type',
					},{
						label: 'Nome',
						field: '_name',
					},{
						label: 'Tipo de Entrega',
						field: '_delivery',
					},{
						label: 'Tipo de Pagamento',
						field: '_payment',
					},{
						label: 'Total',
						field: '_total',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					}
				],
				filterItems: {},
				userCan: '',
				statusOptions: ["Novo Pedido","Aguardando Pagamento","Pagamento Confirmado","Aguardando Envio","Enviado","Concluído"],
				statusCommissionOptions: ["Aguardando Pagamento","Comissão Paga"],
				widgetInfo: {
					sales_direct: 0,
					sales_direct_amount: 0,
					sales_indication: 0,
					sales_indication_amount: 0,
					total_comissions: 0,
					total_comissions_pending: 0
				}
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
			this.loadProducts();
			this.loadClients();
			this.loadSellers();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/sales").then((res) => {
					_this.salesItems = _this.salesItems.concat(res.data);
					_this.loading = false;
					_this.validateWidgetInfos();
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadClients() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/clients").then((res) => {
					_this.clientsOptions = _this.clientsOptions.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadProducts() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/products").then((res) => {
					_this.productsOptions = _this.productsOptions.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadSellers() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/sellers").then((res) => {
					_this.sellersOptions = _this.sellersOptions.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir sales",
					text: "Tem certeza que deseja excluir esse(a) sales?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/sales/"+ID).then((res) => {

							_this.sales.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			filter(){
				let _this = this;
				_this.loading = true;
				let dataFilter = {
					"delivery_type" : _this.filterItems.delivery_type,
					"coupon" : _this.filterItems.coupon,
					"payment_type" : _this.filterItems.payment_type,
					"seller_id" : _this.filterItems.seller_id,
					"client_id" : _this.filterItems.client_id,
					"product_id" : _this.filterItems.product_id,
					"last": ""
				};
				axios.post("/admin/sales/filter", dataFilter).then((res) => {

					_this.loading = false;

					_this.salesItems = [];
					_this.salesItems.push(res.data.data);

				}).catch((err) => {

					_this.loading = false;

					if (err.response.data.errors){
						//set errors
						_this.errors = err.response.data.errors;
					}
					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			editData(item){
				location.href="/admin/sales/"+item.id+"/edit";
			},
			resetForm(){

				this.sale = {
					amount: '',
					subtotal: '',
					delivery_type: '',
					delivery_discount: '',
					coupon: '',
					coupon_discount: '',
					discount: '',
					total: '',
					payment_type: '',
					seller_id: '',
					client_id: '',
					product_id: '',
				};

				this.errors = [];

			},
			saveCommission(){

			},
			validateStatusCommission(item){

				//clear modal infos
				this.modalComission.seller = "";
				this.modalComission.total_sale = "R$ 0.00";
				this.modalComission.total_commission = "R$ 0.00";
				this.modalComission.commission = "5%";
				
				//set total sale
				this.modalComission.total_sale = 'R$ ' + item.total.toFixed(2);

				//validate seller
				if(item.seller_id == null || item.seller_id == 0){
					
					//client
					this.modalComission.seller = item.clients.sellers.name;

				} else {

					//seller
					this.modalComission.seller = item.sellers.name;

				}

				//validate values
				if(item.type == 'direct'){
					this.modalComission.commission = '2%';
					this.modalComission.total_commission = 'R$ ' + (item.total * 0.2).toFixed(2);
				} else {
					this.modalComission.commission = '1%';
					this.modalComission.total_commission = 'R$ ' + (item.total * 0.01).toFixed(2);
				}

				//open modal
				$("#modalUpdateCommission").modal('show');

			},
			validateWidgetInfos(){

				//read all sales
				let _this = this;
				_this.salesItems.map((rowSale) => {

					//validate direct
					if(rowSale.type == 'direct'){
						_this.widgetInfo.sales_direct += 1;
						_this.widgetInfo.sales_direct_amount += rowSale.total;
					}

					//validate indication
					if(rowSale.type == 'indication'){
						_this.widgetInfo.sales_indication += 1;
						_this.widgetInfo.sales_indication_amount += rowSale.total;
					}

					//validate pending commisions
					if(rowSale.status_comission == 0){
						if(rowSale.type == 'direct'){
							_this.widgetInfo.total_comissions_pending += rowSale.total * 0.2;
						} else {
							_this.widgetInfo.total_comissions_pending += rowSale.total * 0.01;
						}
					}

					//validate commissions done
					if(rowSale.status_comission == 1){
						if(rowSale.type == 'direct'){
							_this.widgetInfo.total_comissions_pending += rowSale.total * 0.2;
						} else {
							_this.widgetInfo.total_comissions_pending += rowSale.total * 0.01;
						}
					}

				});

			}
		}
	}
</script>
