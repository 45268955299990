<template>
	<div>
		<div class="row">
			<div class="col-12">

				<div class="card">

					<!-- card header -->
					<div class="card-header">
						<h4>Preencha os dados abaixo</h4>

						<a @click="newData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
					</div>
					<!-- end card header -->

				</div>
			</div>
		</div>

		<div class="row">

			<!-- infos -->
			<div class="col-md-8">
				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Dados</h6>

						<div class="row">

              <!-- title -->
              <div class="col-md-8">
                <div class="form-group">
                  <label class="form-control-label" for="input-title">Título</label>
                  <input type="text" id="input-title" v-bind:class="{'is-invalid': errors.title}"   class="form-control" placeholder="Título" v-model="title" required="false" >
                  <div class="invalid-feedback" v-if="errors.title">
                    <p>{{ errors.title[0] }}</p>
                  </div>
                </div>
              </div>

              <!-- type -->
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label" for="input-type">Tipo</label>
                  <v-select :options="typeOptions" label="label" v-bind:class="{'is-invalid': errors.type}" :reduce="label => label.id" v-model="type"></v-select>
                  <div class="invalid-feedback" v-if="errors.type">
                    <p>{{ errors.type[0] }}</p>
                  </div>
                </div>
              </div>

							<!-- content -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-content">Conteúdo</label>
									<textarea type="text" id="input-content" v-bind:class="{'is-invalid': errors.content}" class="form-control" placeholder="Conteúdo" v-model="content" style="height: 420px !important;" required="false"></textarea>
									<div class="invalid-feedback" v-if="errors.content">
										<p>{{ errors.content[0] }}</p>
									</div>
								</div>
							</div>

						</div>

					</div>
				</div>

			</div>

			<!-- files -->
			<div class="col-md-4">

         <!-- file -->
         <div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Arquivo</h6>

						<div class="row">
							<div class="col-md-12">
								<label class="form-control-label" for="input-file">Clique para selecionar o arquivo</label>
								<div class="custom-file">
									<input type="file" class="custom-file-input" id="file" name="file" accept="*/*" ref="file" lang="pt" v-on:change="handleFileFileUpload()">
									<label class="custom-file-label" for="file">{{selectedFile.name}}</label>
								</div>

							</div>
						</div>

					</div>
				</div>

        <!-- image -->
				<div class="card">
					<div class="card-body">

						<h6 class="heading-small text-muted mb-4">Imagem</h6>

						<div class="row">
							<div class="col-md-12">
								<label class="form-control-label" for="input-picture">Clique para selecionar a imagem</label>
								<div class="custom-file">
									<input type="file" class="custom-file-input" id="picture" name="picture" accept="image/*" ref="picture" lang="pt" v-on:change="handleFilePictureUpload()">
									<label class="custom-file-label" for="logotipo">{{selectedPicture.name}}</label>
								</div>

							</div>
						</div>

						<div class="row list-images">
							<div class="col-md-12 item-image">
								<img :src="selectedPicture.show"/>
							</div>
						</div>

					</div>
				</div>

			</div>

		</div>

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>

	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		data() {
			return {
				title: "",
				content: "",
				type: 1,
				errors: [],
				message: "",
				loading: false,
				fullPage: true,
        selectedPicture: {
					name: 'Nenhuma foto selecionada'
				},
				selectedFile: {
					name: 'Nenhum arquivo selecionado'
				},
				typeOptions:[{
					id: 1,
					label: 'Texto'
				},{
					id: 2,
					label: 'Áudio'
				},{
					id: 3,
					label: 'Vídeo'
				}]
			}
		},
		mounted() {
		},
		methods: {
			handleFileFileUpload(){
				this.selectedFile = {
					name: this.$refs.file.files[0].name,
					file: this.$refs.file.files[0],
					show: URL.createObjectURL(this.$refs.file.files[0])
				};
			},
			handleFilePictureUpload(){
				this.selectedPicture = {
					name: this.$refs.picture.files[0].name,
					file: this.$refs.picture.files[0],
					show: URL.createObjectURL(this.$refs.picture.files[0])
				};
			},
			newData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;

				let formData = new FormData();
				formData.append("title", _this.title);
				formData.append("type", _this.type);
				formData.append("content", _this.content);
				formData.append("file", this.selectedFile.file);
				formData.append("image", this.selectedPicture.file);

				axios.post("/admin/contents", formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

					_this.loading = false;
					_this.resetForm();

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,true);

				}).catch((err) => {

					_this.loading = false;

					if (err.response.data.errors){
						//set errors
						_this.errors = err.response.data.errors;
					}

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			resetForm() {
				this.title = '';
				this.type = 1;
				this.content = '';
				this.selectPicture = {};
				this.selectedFile = {};

				this.errors = [];

			}
		}
	}
</script>
